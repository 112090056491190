import useAuth from "../hooks/auth/useAuth";
import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../img/tusd_web_logo_2.png";
import "../scss/Header.scss";
import { NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router";

function Header() {
  const auth = useAuth();
  return (
    <Navbar className="header" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand>
          <img src={logo} alt="logo" />
          <span className="navbar-brand-name">
            Travis Unified School District
            <br />
            <span className="text-small">Student Services Applications</span>
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="https://www.travisusd.org">Travis USD</Nav.Link>
            <Nav.Link href="https://www.travisusd.org/domain/30">
              Student Services
            </Nav.Link>
          </Nav>
          {auth.user && (
            <NavDropdown title="Admin" className="nav-admin">
              <Nav.Link href="/Admin">Admin</Nav.Link>
              <Nav.Link href="/Admin/Applications">Applications</Nav.Link>
              <Nav.Link href="/Admin/Settings">Settings</Nav.Link>
              <Nav.Link onClick={() => {auth.logout(() => {
                window.location.reload();
              })}}>Logout</Nav.Link>
            </NavDropdown>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
