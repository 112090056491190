import { useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import Field from "./Field";

type Props = {
  label: React.ReactNode;
  name: string;
  disabled?: boolean;
  noValid?: boolean;
  noInvalid?: boolean;
};

const CheckField = (props: Props) => {
  const [field, meta, helpers] = useField({ name: props.name });
  const isValid = !props.noValid && meta.touched && !meta.error;
  const isInvalid =
    !props.noInvalid && meta.touched && meta.error !== undefined;
  return (
      <Form.Check
        {...field}
        type="checkbox"
        checked={field.value}
        isInvalid={isInvalid}
        isValid={isValid}
        label={props.label}
        disabled={props.disabled}
        id={`check-${props.name}`}
      />
  );
};

export default CheckField;
