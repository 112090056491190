import axios from "axios";
import Loading from "components/Loading";
import { useFormikContext } from "formik";
import useApi from "hooks/useApi";
import React from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { InterdistrictApplication } from "tusd-common";
import { isTrue } from "util/util";

type Props = {
  applicationid?: number;
  data: any;
  loading?: boolean;
  refresh?: VoidFunction;
};

export type Verifications = {
  OfficeUseVerifTranscript: boolean;
  OfficeUseRelease: boolean;
  OfficeUseIEP504: boolean;
  OfficeUseStuServicesApproval: boolean;
  OfficeUseSPEDApproval: boolean;
  OfficeUseSchoolAdminApproval: boolean;
  OfficeUseADM: boolean;
  OfficeUseTUSDEmployee: boolean;
};

const OfficeUseVerifications = (props: Props) => {
  // const { data, loading, refresh } = useApi(
  //   `/api/admin/applications/app/interdistrict/${props.applicationid}`
  // );
  const formik = useFormikContext<InterdistrictApplication>();
  const [verifs, setVerifs] = React.useState<Verifications>(null!);

  React.useEffect(() => {
    if (!props.data || props.loading) return;
    let v = {
      OfficeUseVerifTranscript: props.data[0].OfficeUseVerifTranscript,
      OfficeUseRelease: props.data[0].OfficeUseRelease,
      OfficeUseIEP504: props.data[0].OfficeUseIEP504,
      OfficeUseStuServicesApproval: props.data[0].OfficeUseStuServicesApproval,
      OfficeUseSPEDApproval: props.data[0].OfficeUseSPEDApproval,
      OfficeUseSchoolAdminApproval: props.data[0].OfficeUseSchoolAdminApproval,
      OfficeUseADM: props.data[0].OfficeUseADMTUSD,
      OfficeUseTUSDEmployee: props.data[0].OfficeUseTUSDEmployee,
    };
    setVerifs(v);
  }, [props.data]);

  if (props.loading || !props.data || !verifs) return <Loading />;

  const handleCheck: React.ChangeEventHandler<HTMLInputElement> = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let v = { ...verifs };
    if (Object.keys(v).includes(e.target.name))
      // @ts-ignore
      v[e.target.name] = e.target.checked;
    setVerifs(v);
  };

  const saveOfficeUse = async () => {
    let body = {};
    // @ts-ignore
    let res = await axios.put(
      `/api/admin/applications/app/interdistrict/${props.applicationid}/officeuse`,
      verifs
    );
    if (res) {
      if (res.data.status === "Success") props.refresh && props.refresh();
    }
  };

  return (
    <>
      <tr>
        <th className="tw100">Documents</th>
        <td>
          <Form.Check
            label="Verified Uploaded Grade Report / Transcript"
            name="OfficeUseVerifTranscript"
            id="verifiedTranscripts"
            checked={verifs.OfficeUseVerifTranscript}
            onChange={handleCheck}
          ></Form.Check>
          <Form.Check
            label="Verified Uploaded Release Out"
            name="OfficeUseRelease"
            id="verifiedReleaseOut"
            checked={verifs.OfficeUseRelease}
            onChange={handleCheck}
          ></Form.Check>
          <Form.Check
            label={
              <span
                className={
                  isTrue(formik?.values?.AdditionalQuestions?.SpedIEP?.Response)
                    ? ""
                    : "strike"
                }
              >
                Verified Uploaded IEP Documents
              </span>
            }
            disabled={
              !isTrue(formik?.values?.AdditionalQuestions?.SpedIEP?.Response)
            }
            name="OfficeUseIEP504"
            checked={verifs.OfficeUseIEP504}
            id="verifiedIEP"
            onChange={handleCheck}
          ></Form.Check>
          <Form.Check
            label={
              <span
                className={
                  isTrue(formik?.values?.Student?.GuardianActiveDutyMilitary)
                    ? ""
                    : "strike"
                }
              >
                Verified Uploaded Military Documents
              </span>
            }
            disabled={
              !isTrue(formik?.values?.Student?.GuardianActiveDutyMilitary)
            }
            name="OfficeUseADM"
            checked={verifs.OfficeUseADM}
            id="verifiedADM"
            onChange={handleCheck}
          ></Form.Check>
          <Form.Check
            label={
              <span
                className={
                  isTrue(formik?.values?.Student?.GuardianTUSDEmployee)
                    ? ""
                    : "strike"
                }
              >
                Verified Uploaded Employment Documents
              </span>
            }
            disabled={!isTrue(formik?.values?.Student?.GuardianTUSDEmployee)}
            name="OfficeUseTUSDEmployee"
            checked={verifs.OfficeUseTUSDEmployee}
            id="verifiedTUSDEmployee"
            onChange={handleCheck}
          ></Form.Check>
        </td>
        <th className="tw100">Approvals</th>
        <td>
          <Form.Check
            label="Approved by Student Services"
            name="OfficeUseStuServicesApproval"
            id="StuServicesApproved"
            checked={verifs.OfficeUseStuServicesApproval}
            onChange={handleCheck}
          ></Form.Check>
          <Form.Check
            label="Approved by SPED"
            name="OfficeUseSPEDApproval"
            checked={verifs.OfficeUseSPEDApproval}
            id="SPEDApproved"
            onChange={handleCheck}
          ></Form.Check>
          <Form.Check
            label="Approved by School Site Admin"
            name="OfficeUseSchoolAdminApproval"
            checked={verifs.OfficeUseSchoolAdminApproval}
            id="SchoolApproved"
            onChange={handleCheck}
          ></Form.Check>
        </td>
      </tr>
      <tr>
        <td colSpan={4} className="center hidden-print">
          <Button
            onClick={saveOfficeUse}
            variant="success"
            className="hidden-print"
          >
            Save Verifications & Approvals
          </Button>
        </td>
      </tr>
    </>
  );
};

export default OfficeUseVerifications;
