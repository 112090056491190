import Loading from "components/Loading";
import useApi from "hooks/useApi";
import InterdistrictApplication from "pages/applications/InterdistrictApplication/InterdistrictApplication";
import React from "react";
import { useParams } from "react-router";
import { formatBirthdate } from "util/util";

type Props = {};

const ApplicationReview = (props: Props) => {
  const { ApplicationID } = useParams();
  const { data, loading, refresh } = useApi(
    `/api/admin/applications/app/${ApplicationID}`
  );
  
  if (loading || !data) return <Loading />

  return <InterdistrictApplication isAdmin initialData={data} applicationid={ApplicationID ? parseInt(ApplicationID) : undefined} />;
};

export default ApplicationReview;
