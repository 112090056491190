import React from 'react'

type Props = {
  label?: string;
}

const Loading = (props: Props) => {
  return (
    <span><i className="fa fa-spin fa-spinner" /> {props.label || "Loading..."}</span>
  )
}

export default Loading