import React from "react";
import { Alert } from "react-bootstrap";

type Props = {
  disabled?: boolean;
}

const InterdistrictInstructions = (props : Props) => {
  return (
    <Alert>
      <Alert.Heading>
        <i className="fa fa-exclamation-triangle"></i> Read This Before You
        Begin
      </Alert.Heading>
      <p>
        <strong>
          This application may take 15 to 30 minutes to complete. You must
          complete this application in one sitting and upload all documents. If you leave or omit any required documents you must start
          over from the beginning and re-enter student information.
        </strong>
      </p>
      <p>
        <strong>Before you begin</strong> make sure that you have received "release out" paperwork from your district of residence and that you have electronic
        copies of the following documents (PDF or image) ready to upload. You
        will not be able to submit the application without first uploading
        copies of the required documents.
      </p>
      <ul>
        <li>
          Most recent transcripts (grades 9-12) or most recent grade report
          (grades 1-8)
          <br />
          <strong>
            <i>Required for all new students</i>
          </strong>
        </li>
        <li>
          Release documents ("Release Out") from district of residence.
          <br />
          <strong>
            <i>Required for all students</i>
          </strong>
        </li>
        <li>
          Proof of Employment (Most recent Pay Stub)
          <br />
          <i>
            Required if you are requesting this IDA because a parent/legal
            guardian is an employee of Travis USD.
          </i>
        </li>
        <li>
          Military Documents
          <br />
          <i>
            Required if you are requesting this IDA because a parent/legal
            guardian is stationed at Travis Air Force Base
          </i>
        </li>
        <li>
          A copy of your child's IEP or 504 plan
          <br />
          <i>Required if your child has an IEP or 504 plan.</i>
        </li>
        <li>
          A copy of your child's Attendance and Discipline Record
          <br />
          <i>
            Required if your child has any history of attendance or displinary
            problems.
          </i>
        </li>
      </ul>
    </Alert>
  );
};

export default React.memo(InterdistrictInstructions);
