import ConfirmButton from "components/ConfirmButton";
import { FormikState, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { InterdistrictApplication, IDAApplicationEmpty } from "tusd-common";

type Props = {
  disabled?: boolean;
  submitted?: boolean;
  submitting?: boolean;
  message?: string;
  clearMessage?: Function;
  resetForm?: Function;
};

const InterdistrictSubmit = (props: Props) => {
  const formik = useFormikContext<InterdistrictApplication>();

  const disabled = props.disabled || false;
  const hasErrors = (() => {
    let keys = Object.keys(formik.errors);
    if (keys.length === 0) return false;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      // @ts-ignore
      if (Object.keys(formik.errors[key]).length > 0) return true;
    }
    return false;
  })();

  const scrollTo = () => {
    const el = document.querySelector("#studentinfo");
    (el?.parentElement ?? el)?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }

  const submitAnother = () => {
    let newInitial = { ...IDAApplicationEmpty };
    newInitial.Applicant = formik.values.Applicant;
    newInitial.Guardians = formik.values.Guardians;
    newInitial.Student.ReleaseDoc = [];
    newInitial.Applicant.ConfirmAR5117 = "";
    newInitial.Applicant.ConfirmApplication = "";
    newInitial.Applicant.ConfirmRequestConditions = "";
    newInitial.Applicant.ConfirmRequestProcess = "";
    newInitial.Applicant.ConfirmRequestTimeline = "";
    newInitial.Applicant.ConfirmEnrollmentDeadline = "";
    newInitial.Applicant.ConfirmTerms = "";
    newInitial.Siblings = [];
    //@ts-ignore
    formik.resetForm({ values: newInitial });
    props.resetForm && props.resetForm();
    scrollTo();
  };
  return (
    <div className="hidden-print">
      {props.message && props.message !== "" && (
        <Alert variant="warning">{props.message}</Alert>
      )}
      <Alert
        variant={hasErrors ? "danger" : props.submitted ? "warning" : "success"}
        className="hidden-print"
      >
        {props.submitting ? (
          <>
          <i className="fa fa-spin fa-spinner" /> Submitting...
          </>
        ) : props.submitted ? (
          <>
            <Button onClick={submitAnother}>Submit Another</Button> Click this
            button to submit another application for another child.
          </>
        ) : (
          <>
            <Button
              disabled={disabled}
              type="submit"
              onClick={() => {
                formik.submitForm();
              }}
            >
              Submit Application
            </Button>{" "}
            {hasErrors
              ? "There are errors in your application. Please review the fields highlighted red above."
              : "Click this button to submit your application."}
          </>
        )}
      </Alert>

      <Alert variant="secondary" className="hidden-print">
        <ConfirmButton
          variant="secondary"
          onClick={() => {
            formik.resetForm(IDAApplicationEmpty);
            props.resetForm && props.resetForm();
          }}
          confirmText="Are you sure you want to start over? This will delete everything and you will have to re-enter all data."
        >
          Start Over
        </ConfirmButton>{" "}
        Click this button to start over with a blank application.
      </Alert>
    </div>
  );
};

export default InterdistrictSubmit;
