import axios from "axios";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

type Props = {
  applicationid?: number;
  label?: React.ReactNode;
  onUpload?: (success?: boolean) => void;
};

/**
 * Upload Application Document is a component for ADMINS to add a document to an existing application.
 * @param applicationid The Application ID to upload to
 * @param label Optional label for the application
 * @param onUpload Optional void function to call after uploading. This is passed a single bool parameter, true if successful.
 * @returns
 */
const UploadApplicationDocument = ({
  applicationid,
  label,
  onUpload,
}: Props) => {
  const controlRef = React.useRef<HTMLInputElement & HTMLButtonElement>(null);
  const [field, setField] = React.useState<string>(null!);
  const [files, setFiles] = React.useState<any>(null!);

  if (!applicationid) return null;

  const handleChange = (d: any) => {
    setFiles(d.target.files);
  };

  const handleSubmit = () => {
    if (!field) return;
    if (!files || files.length === 0) return;

    let fData = new FormData();
    for (let i = 0; i < files.length; i++) {
      fData.append("" + i, files[i]);
    }
    fData.append("field", field);
    fData.append("form", "UploadToExistingApplication");

    axios.post(`/api/files/application/${applicationid}`, fData).then((res) => {
      console.log(res);
    });
  };

  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type="file"
        className="hidden"
        ref={controlRef}
        name={`uploader-addfiletoapp`}
        onChange={handleChange}
        accept="image/*,.pdf,.docx"
      />
      <InputGroup>
        <InputGroup.Text>Document Type</InputGroup.Text>
        <Form.Select onChange={(e) => setField(e.target.value)} value={field}>
          <option value="">Select type</option>
          <option value="Student.TranscriptsDoc">
            Transcript / Grade Report
          </option>
          <option value="Student.GuardianActiveDutyMilitaryDoc">
            Military Documents
          </option>
          <option value="Student.GuardianTUSDEmployeeDoc">
            Employment Documents
          </option>
          <option value="Student.ReleaseDoc">Release out</option>
          <option value="AdditionalQuestions.SpedIEP.Doc">
            IEP / 504 Plan
          </option>
        </Form.Select>
        <InputGroup.Text>
          {files && files.length > 0 ? (
            <>{files[0].name}</>
          ) : (
            <>No document selected</>
          )}
        </InputGroup.Text>
        <Button
          onClick={() => {
            if (controlRef !== null && controlRef.current !== null)
              controlRef.current.click();
          }}
        >
          <i className="fa fa-file-text" /> Choose Document
        </Button>
        <Button
          variant="success"
          disabled={!field || field === "" || !files || files.length === 0}
          onClick={handleSubmit}
        >
          <i className="fa fa-save" /> Save
        </Button>
      </InputGroup>
    </>
  );
};

export default UploadApplicationDocument;
