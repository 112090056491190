import { useField } from 'formik'
import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap';

type Props = {
  name: string;
}

const ValidationHelper = (props: Props) => {
  const [field, meta, helpers] = useField({name: props.name});
  useEffect(() => {
    helpers.setTouched(true, true);
  }, [props.name])
  
  if (!meta.error) return null;
  return (
    <Alert variant="danger">{meta.error}</Alert>
  )
}

export default ValidationHelper