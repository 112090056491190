import React from "react";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import DataController, { SortOrder } from "components/DataController";
import DataTable, { Header } from "components/DataTable";
import useApi from "hooks/useApi";
import "scss/Applications.scss";
import { formatGrade, formatPhone, isTrue, parseAcademicYear } from "util/util";
import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import ApplicationStatusSelector from "components/forms/ApplicationStatusSelector";
import { ApplicationStatus } from "tusd-common";
import InterdistrictApplicationStats from "components/InterdistrictApplicationStats";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

export enum ApplicationStatuses {
  "Applied",
  "Approved",
  "Waitlist",
  "Pending",
  "Completed",
}

export enum State {
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
}

export enum ApplicationType {
  "Inter",
  "Intra",
  "SRA",
  "Caregiver",
}

interface ApplicationSibling {
  ApplicationID: number;
  StudentLastName: string;
  StudentFirstName: string;
  StudentIncomingGrade: number;
}

interface Application {
  ApplicationID: number;
  AcademicYear: string;
  ApplicationStatus: ApplicationStatus;
  ApplicationType: ApplicationType;
  StudentLastName: string;
  StudentFirstName: string;
  ReasonNewStudent: boolean;
  ReasonNewStudentText: string;
  ReasonSpedIEP?: boolean;
  ReasonActiveDutyMilitary?: boolean;
  ApplicationDate: Date;
  ApplicantLastName: string;
  ApplicantFirstName: string;
  PrimaryEmailAddress: string;
  PrimaryPhoneNumber: string;
  StudentIncomingGrade: number;
  Siblings: ApplicationSibling[];
  refresh?: Function;
}

const YearSelector = () => {
  const { data, loading } = useApi("/api/admin/applications/submittedYears");
  if (loading) return <>Loading...</>;
  if (!data) return <>Error...</>;

  return (
    <React.Fragment>
      <ul>
        {data
          .sort((a: any, b: any) => (a.AcademicYear < b.AcademicYear ? 1 : 0))
          .map((yr: any) => (
            <li key={yr.AcademicYear}>
              <Link to={`./${yr.AcademicYear - 1}-${yr.AcademicYear}`}>
                {yr.AcademicYear - 1}-{yr.AcademicYear}
              </Link>
            </li>
          ))}
      </ul>
    </React.Fragment>
  );
};

interface ApplicationRowProps {
  data: Application;
  onSave: (id: number, status: ApplicationStatus) => void;
}

function ApplicationRow(data: Application) {
  const navigate = useNavigate();

  const deleteApplication = () => {
    axios
      .delete(`/api/admin/applications/app/${data.ApplicationID}`)
      .then((res) => {
        console.log(`Deleted application ${data.ApplicationID}`, res);
        if (data.refresh) data.refresh();
      });
  };

  return (
    <tr
      key={data.ApplicationID}
      className={`application-${data.ApplicationStatus}`}
    >
      <td>{new Date(data.ApplicationDate).toLocaleString()}</td>
      <td>
        {data.StudentLastName}, {data.StudentFirstName}
      </td>
      <td>{formatGrade(data.StudentIncomingGrade)}</td>
      <td>
        <ApplicationStatusSelector
          applicationid={data.ApplicationID}
          status={data.ApplicationStatus}
        />
      </td>
      <td>
        {data.ApplicationType}{" "}
        {data.ReasonSpedIEP ? (
          <OverlayTrigger overlay={<Tooltip>Student has IEP</Tooltip>}>
            <Badge bg="success">IEP</Badge>
          </OverlayTrigger>
        ) : (
          <></>
        )}{" "}
        {data.ReasonActiveDutyMilitary ? (
          <OverlayTrigger overlay={<Tooltip>Active Duty Military</Tooltip>}>
            <Badge bg="warning" text="dark">MIL</Badge>
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </td>
      <td>{data.ReasonNewStudentText}</td>
      <td>
        <span>
          {data.ApplicantLastName}, {data.ApplicantFirstName}
        </span>
        <br />
        <a
          style={{ fontSize: ".75em" }}
          href={`mailto:${data.PrimaryEmailAddress}`}
        >
          {data.PrimaryEmailAddress}
        </a>
        <br />
        <span style={{ fontSize: ".8em" }}>
          {formatPhone(data.PrimaryPhoneNumber)}
        </span>
      </td>
      <td>
        {data.Siblings.map((sib: ApplicationSibling) => (
          <li>
            <span>
              {sib.StudentLastName}, {sib.StudentFirstName}
            </span>
          </li>
        ))}
      </td>
      <td>
        <Button
          className="d-print-none"
          size="sm"
          onClick={() => navigate(`./${data.ApplicationID}`)}
        >
          <i className="fa fa-eye" />
        </Button>
        &nbsp;
        <ConfirmButton
          variant="danger"
          size="sm"
          onClick={deleteApplication}
          confirmText={`Are you sure you want to delete the application for ${data.StudentLastName}, ${data.StudentFirstName}`}
        >
          <i className="fa fa-trash" />
        </ConfirmButton>
      </td>
    </tr>
  );
}

const headers: Array<Header> = [
  {
    field: "ApplicationDate",
    label: "AppliedDate",
    sortable: true,
  },
  {
    field: "StudentFullName",
    label: "Student",
    searchable: true,
    sortable: true,
  },
  {
    field: "StudentIncomingGrade",
    label: "Grade",
    searchable: true,
    sortable: true,
  },
  {
    field: "ApplicationStatus",
    label: "Status",
    filterable: true,
    filters: Object.values(ApplicationStatuses)
      .filter((key) => isNaN(parseInt(key.toString())))
      .map((key) => ({ value: key.toString(), label: key.toString() })),
    searchable: true,
  },
  {
    field: "ApplicationTypeString",
    label: "Type",
    searchable: true,
  },
  {
    field: "ReasonNewStudentText",
    label: "Renewal",
    searchable: true,
    sortable: true,
    filterable: true,
    filters: [
      { label: "New", value: "New" },
      { label: "Renewal", value: "Renewal" },
    ],
  },
  {
    field: "Applicant",
    label: "Applicant",
    sortable: true,
    searchable: true,
  },
  {
    field: "SiblingsLabel",
    label: "Sibling Applications",
    searchable: false,
    sortable: false,
  },
];

function Applications() {
  // const [applications, setApplications] = useState<Array<Application>>([]);
  const { year } = useParams();
  const { data, loading, refresh } = useApi(
    `/api/admin/applications/${parseAcademicYear(year)}`
  );

  if (!year) return <YearSelector />;
  if (loading) return <p>Loading</p>;
  if (!data) return <p>Error</p>;

  let alteredData = [...data];
  for (let i = 0; i < alteredData.length; i++) {
    let a = alteredData[i];
    a.refresh = refresh;
    a.StudentFullName = `${a.StudentLastName}, ${a.StudentFirstName}`;
    alteredData[i].Applicant = `${a.LastName}, ${a.FirstName}, ${
      a.PrimaryEmailAddress
    }, ${formatPhone(a.PrimaryPhoneNumber)}`;
    alteredData[i].ApplicationTypeString = `${a.ApplicationType} - ${
      a.ReasonSpedIEP ? "IEP" : ""
    } - ${a.ReasonActiveDutyMilitary ? "MILITARY" : ""}`;
    a.Siblings = [];
    a.ReasonNewStudentText = isTrue(a.ReasonNewStudent) ? "New" : "Renewal";
    for (let j = 0; j < alteredData.length; j++) {
      if (
        alteredData[j].PrimaryEmailAddress === a.PrimaryEmailAddress &&
        alteredData[j].ApplicationID !== a.ApplicationID
      ) {
        a.Siblings.push({
          ApplicationID: alteredData[j].ApplicationID,
          StudentLastName: alteredData[j].StudentLastName,
          StudentFirstName: alteredData[j].StudentFirstName,
          StudentIncomingGrade: alteredData[j].StudentIncomingGrade,
        });
      }
    }
    a.SiblingsLabel = "";
    if (a.Siblings.length > 0) {
      a.SiblingsLabel = a.Siblings.map(
        (sib: any) =>
          `${sib.StudentLastName} ${sib.StudentFirstName} ${sib.ApplicationID}`
      );
    }
  }
  /*
{
  "ApplicationID": 177,
  "ApplicantLastName": "Perez Catalan",
  "ApplicantFirstName": "Lucina",
  "PrimaryEmailAddress": "lpgrad09@yahoo.com",
  "PrimaryPhoneNumber": "7078536536",
  "AcademicYear": 2024,
  "ApplicationStatus": "Pending",
  "ApplicationStatusDate": null,
  "ApplicationType": "Inter",
  "ReasonNewStudent": [
    false,
    false
  ],
  "StudentLastName": "Buenaluz",
  "StudentFirstName": "Maeleah",
  "ReasonActiveDutyMilitary": false,
  "School": 0,
  "ReasonSpedIEP": false,
  "ApplicationDate": "2023-02-13T17:31:08.567Z",
  "StudentIncomingGrade": 9,
  "StudentFullName": "Buenaluz, Maeleah",
  "Applicant": "undefined, undefined, lpgrad09@yahoo.com, (707) 853-6536",
  "Siblings": [],
  "ReasonNewStudentText": "Renewal",
  "SiblingsLabel": ""
}
  */

  const downloadFile = ({
    data,
    fileName,
    fileType,
  }: {
    data: string;
    fileName: string;
    fileType: string;
  }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCSV = () => {
    console.log("Altered Data: ", alteredData);
    let headers = [
      '"Application ID"',
      '"Application Date"',
      '"Applicant Last Name"',
      '"Applicant First Name"',
      '"Primary Email Address"',
      '"Primary Phone Number"',
      '"Academic Year"',
      '"Application Status"',
      '"Application Status Date"',
      '"Application Type"',
      '"School Code"',
      '"Student Last Name"',
      '"Student First Name"',
      '"Grade"',
      '"Active Duty Military"',
      '"SpedIEP"',
      '"New or Renewal"',
      '"Siblings"',
    ];
    let headerStr = headers.join(",");
    let apps = [];
    for (let i = 0; i < alteredData.length; i++) {
      let app: any = alteredData[i];
      apps.push(
        '"' +
          [
            app.ApplicationID,
            app.ApplicationDate,
            app.ApplicantLastName,
            app.ApplicantFirstName,
            app.PrimaryEmailAddress,
            app.PrimaryPhoneNumber,
            app.AcademicYear - 1 + "-" + app.AcademicYear,
            app.ApplicationStatus,
            app.ApplicationStatusDate,
            app.ApplicationType,
            app.School,
            app.StudentLastName,
            app.StudentFirstName,
            app.StudentIncomingGrade,
            app.ReasonActiveDutyMilitary,
            app.ReasonSpedIEP,
            app.ReasonNewStudentText,
            app.SiblingsLabel,
          ].join('","') +
          '"'
      );
    }
    downloadFile({
      data: [headerStr, ...apps].join("\n"),
      fileName: `TUSD_IDA_INTER_${new Date().toLocaleString()}.csv`,
      fileType: "csv",
    });
  };

  return (
    <div className="Applications">
      <div style={{ float: "right" }}>
        <Button onClick={exportToCSV}>Save as CSV</Button>
      </div>
      <DataController
        data={alteredData}
        paged
        defaultSort={{ sortKey: "ApplicationDate", sortOrder: SortOrder.DESC }}
      >
        <DataTable headers={headers} row={ApplicationRow} />
      </DataController>
      <InterdistrictApplicationStats applications={data} />
    </div>
  );
}

Applications.propTypes = {};

export default Applications;
