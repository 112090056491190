import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import Field from "./Field";

type Props = {
  name: string;
  label: React.ReactNode;
  disabled?: boolean;
  noValid?: boolean;
  noInvalid?: boolean;
  showage?: boolean;
  required?: boolean;
};

const pad = (value : string | number, num : number, padding : string) => {
  let val = value.toString();
  for (let i = val.length; i < num; i++) {
    val = padding + val;
  }
  return val;
};
const formatDate = (date : Date | number) => {
  let dt = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000);
  return `${dt.getFullYear()}-${pad(dt.getMonth() + 1, 2, "0")}-${pad(
    dt.getDate(),
    2,
    "0"
  )}`;
};

const BirthdateField = (props: Props) => {
  return (
    <Field name={props.name} label={props.label}>
      {({ label, name, disabled, isValid, isInvalid, field, meta }) => (
        <>
          <Form.Group>
            <Form.Label>{label}{props.required && (<span className="label-required">*</span>)}</Form.Label>
            <InputGroup>
              <Form.Control
                type="date"
                {...field}
                {...props}
                value={props.disabled ? formatDate(field.value) : field.value}
                isValid={isValid}
                isInvalid={isInvalid}
                disabled={props.disabled}
              />
              {props.showage === true && (
                <InputGroup.Text>
                  {field.value
                    ? Math.floor(
                        (new Date().getTime() -
                          new Date(field.value).getTime()) /
                          (1000 * 60 * 60 * 24 * 365.24999)
                      )
                    : "#"}{" "}
                </InputGroup.Text>
              )}
              <Form.Control.Feedback type="invalid">
                {meta.error}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </>
      )}
    </Field>
  );
};

export default BirthdateField;
