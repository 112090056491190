import React from 'react'
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types'

export interface PagerProps {
  page: number;
  pageCount: number;
  onSelectPage: (page: number) => void;
}

export default function Pager(props: PagerProps) {
  return (
    <Pagination
      className="Pager">
      <Pagination.First
        disabled={props.page < 1}
        onClick={() => props.onSelectPage(0)}
      />
      <Pagination.Prev
        disabled={props.page < 1}
        onClick={() => props.onSelectPage(props.page - 1)}
      />
      <Pagination.Item
        disabled={props.page < 1}
        onClick={() => props.onSelectPage(props.page - 1)}
      >{props.page}</Pagination.Item>
      <Pagination.Item active>
        {props.page + 1}{props.pageCount && " of " + props.pageCount}
      </Pagination.Item>
      <Pagination.Item
        disabled={!(!props.pageCount || props.page < props.pageCount - 1)}
        onClick={() => props.onSelectPage(props.page + 1)}>
        {props.page + 2}
      </Pagination.Item>
      <Pagination.Next
        disabled={!(!props.pageCount || props.page < props.pageCount - 1)}
        onClick={() => props.onSelectPage(props.page + 1)} />
      <Pagination.Last
        disabled={(!props.pageCount || props.page === props.pageCount-1 || props.pageCount <= 0)}
        onClick={() => props.onSelectPage(props.pageCount - 1)} />
    </Pagination>
  )
}

Pager.propTypes = {
  loading: PropTypes.bool,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  onSelectPage: PropTypes.func
}
Pager.defaultProps = {
  loading: false,
  page: 1,
  pageCount: null,
  onSelectPage: null,
}
