import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import useApi from "hooks/useApi";
import React from "react";
import { Col, Row, Table, Button, InputGroup, Form } from "react-bootstrap";

type Props = {
  applicationid?: number;
  className?: string;
};

const ApplicationNotes = (props: Props) => {
  const { data, loading, refresh } = useApi(
    `/api/admin/applications/app/${props.applicationid}/notes`
  );
  const [note, setNote] = React.useState<string>("");

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNote(e.target.value);
  };

  const saveNote = async () => {
    if (note !== "") {
      let res = await axios.post(
        `/api/admin/applications/app/${props.applicationid}/notes`,
        { Note: note }
      );
      console.log("Posted...");
      console.log("SaveNote Res", res);
      if (res?.data?.status) {
        refresh();
        setNote("");
      }
      // TODO: Handle failure
    }
  };

  const deleteNote = async (NoteID: number) => {
    let res = await axios.delete(
      `/api/admin/applications/app/${props.applicationid}/notes/${NoteID}`
    );
    if (res?.data?.status) {
      refresh();
    }

    // TODO: Handle failure.
  };

  return (
    <>
      {loading || !data ? (
        <p>Loading...</p>
      ) : (
        <Table size="sm" striped bordered className={props.className + " noteTable"}>
          <thead>
            <tr>
              <th className="tw200">Date</th>
              <th className="tw200">User</th>
              <th>Note</th>
              <th className="tw50"></th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={4} style={{height: "100px"}}></td>
              </tr>
            )}
            {data.map((note: any, i: number) => (
              <tr key={`note-${i}`}>
                <td className="tw200">{note.LastChangeDate}</td>
                <td className="tw200">{note.Username}</td>
                <td>{note.Note}</td>
                <td className="tw50">
                  <ConfirmButton
                    variant="danger"
                    confirmText="Are you sure you want to delete this note?"
                    onClick={() => deleteNote(note.NoteID)}
                  >
                    <i className="fa fa-trash" />
                  </ConfirmButton>
                </td>
              </tr>
            ))}
            <tr className="hidden-print">
              <th colSpan={4}>New Note:</th>
            </tr>
            <tr className="hidden-print">
              <td colSpan={4}>
                <InputGroup className="hidden-print">
                  <Form.Control
                    type="textarea"
                    placeholder="Type new note..."
                    value={note}
                    onChange={handleChange}
                  />
                  <Button variant="success" onClick={() => saveNote()}>
                    Save Note <i className="fa fa-save" />
                  </Button>
                </InputGroup>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ApplicationNotes;
