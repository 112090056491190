import { useField } from "formik";
import React from "react";
import { Alert, Form, Row, Col } from "react-bootstrap";

type Props = {
  name: string;
  label?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  noValid?: boolean;
  noInvalid?: boolean;
};

const InitialField = (props: Props) => {
  const [field, meta] = useField({ name: props.name, type: "text" });
  const isValid = !props.noValid && meta.touched && !meta.error;
  const isInvalid =
    !props.noInvalid && meta.touched && meta.error !== undefined;
  return (
    <Alert variant={meta.error ? "danger" : "warning"}>
      <Form.Group>
        <Row>
          <Col
            xs={{ order: "last", span: 12 }}
            md={{ order: "first", span: 2 }}
            xl={{ order: "first", span: 1 }}
          >
            <Form.Control
              type="text"
              className={props.className}
              {...field}
              value={field.value || ""}
              disabled={props.disabled}
              isValid={isValid}
              isInvalid={isInvalid}
              placeholder="Initial"
              id={props.name}
            />
          </Col>
          {props.label && (
            <Col xs={12} md={10} xl={11}>
              {props.label}
            </Col>
          )}
          {meta.error && <Col xs={12}><i><b>{meta.error}</b></i></Col>}
        </Row>
      </Form.Group>
    </Alert>
  );
};

export default InitialField;
