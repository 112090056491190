import Login from "../pages/Login";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../pages/Home';
import NotFound from "../pages/NotFound";
import Applications from "../pages/private/Applications";
import InterdistrictApplication from "../pages/applications/InterdistrictApplication/InterdistrictApplication";
import PrivateRoute from "../hooks/auth/PrivateRoute";
import Admin from "../pages/private/Admin";
import ApplicationReview from "pages/private/ApplicationReview";
import Users from "pages/private/Users";
import Settings from "pages/private/Settings";

export default function PageRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/Apply">
          <Route path="/Apply" element={<Home />} />
          <Route
            path="/Apply/Interdistrict"
            element={<InterdistrictApplication />}
          />
        </Route>
        {/* <Route path="/test" element={<Test />} />

        <Route path="/login" element={<Login />} /> */}
        <Route path="/Admin">
          <Route path="/Admin/Applications" element={<PrivateRoute><Applications /></PrivateRoute>} />
          <Route path="/Admin/Applications/:year" element={<PrivateRoute><Applications /></PrivateRoute>} />
          <Route path="/Admin/Applications/:year/:ApplicationID" element={<PrivateRoute><ApplicationReview /></PrivateRoute>} />
          {/* <Route
            path="/Admin/Applications/:applicationid"
            element={<Application />}
          /> */}
          <Route path="/Admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
          <Route path="/Admin/Users" element={<PrivateRoute levels={["admin"]}><Users /></PrivateRoute>} />
          <Route path="/Admin/Settings" element={<PrivateRoute levels={["admin"]}><Settings /></PrivateRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/Admin/Login" element={<Login />}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
