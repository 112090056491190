import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";
import { string } from "yup";

interface ChildProps {
  label: React.ReactNode;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  required?: boolean;
  field: any;
  meta: any;
  helpers: any;
}

export interface FieldProps {
  label: React.ReactNode;
  name: string;
  className?: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  noValid?: boolean;
  noInvalid?: boolean;
  required?: boolean;
  children?: React.FunctionComponent<ChildProps>;
}

function Field({
  label,
  name,
  type,
  disabled,
  noValid,
  noInvalid,
  placeholder,
  className,
  required,
  children,
}: FieldProps) {
  const [field, meta, helpers] = useField({ name, type });
  const isValid = !noValid && meta.touched && !meta.error;
  const isInvalid = !noInvalid && meta.touched && meta.error !== undefined;

  return (
    <Form.Group>
      {children ? (
        children({
          label,
          name,
          disabled,
          required,
          isValid,
          isInvalid,
          field,
          meta,
          placeholder,
          helpers
        })
      ) : (
        <>
          <Form.Label htmlFor={name}>
            {label}
            {required && <span className="label-required">*</span>}
          </Form.Label>
          <Form.Control
            type={type}
            className={className}
            {...field}
            value={field.value || ""}
            disabled={disabled}
            isValid={isValid}
            isInvalid={isInvalid}
            placeholder={placeholder}
            id={name}
          />
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        </>
      )}
    </Form.Group>
  );
}

export default Field;
