import BirthdateField from "components/forms/BirthdateField";
import Field from "components/forms/Field";
import GradeSelector from "components/forms/GradeSelector";
import StateSelector from "components/forms/StateSelector";
import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { Form, Card, Row, Col, Button } from "react-bootstrap";
import {
  InterdistrictApplication,
  IDAApplicationEmpty as emptySchema,
} from "tusd-common";

type Props = {
  disabled?: boolean;
};

const InterdistrictSiblings = (props: Props) => {
  const disabled = props.disabled || false;
  const formik = useFormikContext<InterdistrictApplication>();
  return (
    <Card>
      <Card.Header>
        <Card.Title>Siblings</Card.Title>
      </Card.Header>
      <Card.Body>
        <p>
          Please add any school-aged siblings.
        </p>
        <hr />
        <Row>
          <FieldArray
            name="Siblings"
            render={(arrayHelpers) => (
              <>
                {formik.values.Siblings &&
                  formik.values.Siblings.length > 0 &&
                  formik.values.Siblings.map((g, i) => (
                    <Row key={i}>
                      <Col>
                        <Row>
                          <Col sm={4}>
                            <Field
                              required
                              name={`Siblings.${i}.LastName`}
                              disabled={disabled}
                              label="Last Name"
                            />
                          </Col>
                          <Col sm={4}>
                            <Field
                              required
                              name={`Siblings.${i}.FirstName`}
                              disabled={disabled}
                              label="First Name"
                            />
                          </Col>
                          <Col sm={3}>
                            <BirthdateField
                              required
                              name={`Siblings.${i}.Birthdate`}
                              disabled={disabled}
                              label="Birthdate / Age"
                              showage
                            />
                          </Col>
                          <Col sm={1}>
                            {!disabled && (
                              <Form.Group>
                                <Form.Label>Remove</Form.Label>
                                <br />
                                <Button
                                  variant="danger"
                                  onClick={arrayHelpers.handleRemove(i)}
                                >
                                  <i className="fa fa-times" />
                                </Button>
                              </Form.Group>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={3}>
                            <GradeSelector
                              required
                              name={`Siblings.${i}.Grade`}
                              disabled={disabled}
                              label="Grade"
                            />
                          </Col>
                          <Col sm={3}>
                            <Field
                              required
                              name={`Siblings.${i}.School`}
                              disabled={disabled}
                              label="Most Recent School Name"
                            />
                          </Col>
                          <Col sm={3}>
                            <Field
                              required
                              name={`Siblings.${i}.SchoolCity`}
                              disabled={disabled}
                              label="Most Recent School City"
                            />
                          </Col>
                          <Col sm={3}>
                            <StateSelector
                              required
                              name={`Siblings.${i}.SchoolState`}
                              label="Most Recent School State"
                              disabled={disabled}
                            />
                          </Col>
                        </Row>
                        <hr style={{ marginTop: "10px" }} />
                      </Col>
                    </Row>
                  ))}
                <br />
                <Row>
                  <Col xs={2}>
                    {!disabled &&
                      (!formik.values.Siblings ||
                        formik.values.Siblings.length < 10) && (
                        <Button
                          onClick={() =>
                            arrayHelpers.push(emptySchema.Siblings[0])
                          }
                        >
                          Add Sibling
                        </Button>
                      )}
                  </Col>
                </Row>
              </>
            )}
          />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InterdistrictSiblings;
