import React from 'react'
import { Link } from 'react-router-dom'

type Props = {}

const Admin = (props: Props) => {
  return (
    <div>
      <h1>Admin Panel</h1>
      <ul>
        <li><u><Link to="/Admin/Applications">Applications</Link></u> - Review Submitted Applications.</li>
      </ul>
    </div>
  )
}

export default Admin