import axios from "axios";
import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import Applications, { ApplicationStatus } from "tusd-common";

type Props = {
  applicationid?: number;
  status: ApplicationStatus;
  onSave?: (newStatus?: ApplicationStatus) => void;
};

const ApplicationStatusSelector = (props: Props) => {
  const [status, setStatus] = React.useState<ApplicationStatus>(props.status);
  const [initialStatus, setInitialStatus] = React.useState<ApplicationStatus>(props.status);
  const [saved, setSaved] = React.useState<boolean>(true);

  React.useEffect(() => {
    setInitialStatus(props.status);
    setStatus(props.status);
  }, [props.status])

  const onChange = (s: ApplicationStatus) => {
    setStatus(s);
    if (s !== initialStatus) {
      setSaved(false);
    } else {
      setSaved(true);
    }
  };

  const onSave = (s: ApplicationStatus) => {
    console.log("Saving status", status);
    axios
      .put(`/api/admin/applications/app/${props.applicationid}/status`, {
        ApplicationStatus: status,
      })
      .then((res) => {
        setSaved(true);
        setInitialStatus(status);
        props.onSave && props.onSave(s);
      });
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant={saved ? "secondary" : "danger"}>
        {status}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Applications.AllStatuses.map((s: ApplicationStatus, i: number) => (
          <Dropdown.Item onClick={() => onChange(s)} key={i}>
            {s}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
      {!saved && (
        <>
          <Button
            variant="success"
            onClick={() => {
              onSave(status);
            }}
          >
            <i className="fa fa-floppy-o" />
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              setStatus(initialStatus);
              setSaved(true);
            }}
          >
            <i className="fa fa-undo" />
          </Button>
        </>
      )}
    </Dropdown>
  );
};

export default ApplicationStatusSelector;
