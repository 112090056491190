import { useFormikContext } from "formik";
import React from "react";
import { Alert, Form, Row } from "react-bootstrap";
import Field from "./Field";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  noValid?: boolean;
  noInvalid?: boolean;
  required?: boolean;
};

interface FormikValues {
  Student: {
    IncomingGrade: number;
  };
}

const SchoolSelector = (props: Props) => {
  const formik = useFormikContext<FormikValues>();
  if (formik.values.Student === undefined || formik.values.Student === null) {
    return <></>;
  }
  return (
    <Field name={props.name} label={props.label} disabled={props.disabled}>
      {({ label, name, disabled, isValid, isInvalid, field, meta }) => (
        <>
          {props.label && (
            <Form.Label>
              {label}
              {props.required && <span className="label-required">*</span>}
            </Form.Label>
          )}
          <Form.Select
            {...field}
            isInvalid={isInvalid}
            isValid={isValid}
            required={props.required}
            disabled={props.disabled}
          >
            <option value="0">Select a School</option>
            {formik.values.Student.IncomingGrade < 7 && (
              <option value="5">Cambridge Elementary</option>
            )}
            {formik.values.Student.IncomingGrade < 7 && (
              <option value="1">Center Elementary</option>
            )}
            {formik.values.Student.IncomingGrade < 7 && (
              <option value="6">Foxboro Elementary</option>
            )}
            {formik.values.Student.IncomingGrade < 7 && (
              <option value="3">Scandia Elementary</option>
            )}
            {formik.values.Student.IncomingGrade < 7 && (
              <option value="2">Travis Elementary</option>
            )}
            {formik.values.Student.IncomingGrade > 8 && (
              <option value="21">Vanden High</option>
            )}
            {formik.values.Student.IncomingGrade > 6 &&
              formik.values.Student.IncomingGrade < 9 && (
                <option value="4">Golden West Middle</option>
              )}
            {formik.values.Student.IncomingGrade > 8 && (
              <option value="23">TEC (Continuation)</option>
            )}
          </Form.Select>
          {(field.value === "2" || field.value === "3") && (
            <Alert variant="warning">
              Please note that{" "}
              {field.value === "2" ? "Travis Elementary" : "Scandia Elementary"}{" "}
              is on Travis Air Force Base. You and your child must have access
              to Travis Air Force Base to enroll.
            </Alert>
          )}
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        </>
      )}
    </Field>
  );
};

export default SchoolSelector;
