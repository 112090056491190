import GradeSelector from "../../../components/forms/GradeSelector";
import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Field from "../../../components/forms/Field";
import BirthdateField from "components/forms/BirthdateField";

type Props = {
  disabled?: boolean;
};

const InterdistrictStudentInfo = (props: Props) => {
  const disabled = props.disabled || false;
  return (
    <Card>
      <Card.Header  id="studentinfo">
        <Card.Title>Basic Student Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <p>
          Please enter information for the student that you are applying to have
          attend Travis USD on an Inter District Attendance Agreement.
        </p>
        <hr />
        <Row>
          <Col sm={4}>
            <Field
              required
              label="Last Name"
              name="Student.LastName"
              type="text"
              disabled={disabled}
            />
          </Col>
          <Col sm={4}>
            <Field
              required
              label="First Name"
              name="Student.FirstName"
              type="text"
              disabled={disabled}
            />
          </Col>
          <Col sm={4}>
            <Field
              required
              label="Middle Name"
              name="Student.MiddleName"
              type="text"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4}>
            <BirthdateField
              required
              label="Birthdate"
              name="Student.Birthdate"
              disabled={disabled}
            />
          </Col>
          <Col xs={12} sm={4}>
            <GradeSelector
              required
              label="Incoming Grade"
              name="Student.IncomingGrade"
              disabled={disabled}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InterdistrictStudentInfo;
