// import IDAApplication from './IDAApplication';
const IDAApplication = require('./IDAApplication');
const lists = require('./lists');
// import { AllStates, AllStatuses, AllTypes, AllYears } from './lists'


// export { IDAApplication };
// export { AllStates };
// export { AllStatuses }
// export { AllTypes }
// export { AllYears }

const all = {
  IDAApplication: IDAApplication.schema,
  IDAApplicationEmpty: IDAApplication.empty,
  AllStates: lists.AllStates,
  AllStatuses: lists.AllStatuses,
  AllTypes: lists.AllTypes,
  AllYears: lists.AllYears,
}

// export default all;
module.exports = all;