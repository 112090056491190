import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";
import { string } from "yup";
import { isExternalModuleReference } from "typescript";

export interface TextareaProps {
  label: React.ReactNode;
  name: string;
  placeholder?: string;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  noValid?: boolean;
  noInvalid?: boolean;
}

function Textarea({
  label,
  name,
  type,
  disabled,
  noValid,
  noInvalid,
  required,
  placeholder,
}: TextareaProps) {
  const [field, meta] = useField({ name, type });
  const isValid = !noValid && meta.touched && !meta.error;
  const isInvalid = !noInvalid && meta.touched && meta.error !== undefined;
  const textRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const height = textRef.current.scrollHeight;
      textRef.current.style.height = height + "px";
    }
  }, []);

  const onBlur = (e : any) => {
    if (textRef && textRef.current) {
      textRef.current.style.height = "0px";
      const height = textRef.current.scrollHeight;
      textRef.current.style.height = height + "px";
    }
    field.onBlur(e);
  }

  return (
    <Form.Group>
      <Form.Label>{label}{required && (<span className="label-required">*</span>)}</Form.Label>
      <Form.Control
        type={type}
        {...field}
        value={field.value || ""}
        disabled={disabled}
        isValid={isValid}
        as="textarea"
        isInvalid={isInvalid}
        placeholder={placeholder}
        id={name}
        ref={textRef}
        onBlur={onBlur}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default Textarea;
