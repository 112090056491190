import React from "react";
import { Card, Row, Col, Alert } from "react-bootstrap";
import { useFormikContext } from "formik";
import { InterdistrictApplication } from "tusd-common";
import Field from "components/forms/Field";
import InitialField from "components/forms/InitialField";
type Props = {
  disabled?: boolean;
};

const InterdistrictAgreement = (props: Props) => {
  const formik = useFormikContext<InterdistrictApplication>();
  const disabled = props.disabled || false;

  return (
    <Card className="singlepage">
      <Card.Body>
        <Row>
          <Col>
            <Card.Title>
              Agreement for Inter District Attendance in Travis USD
            </Card.Title>
            <Card.Title>
              <strong>Student Name:</strong>{" "}
              {formik.values.Student &&
                formik.values.Student.LastName +
                  ", " +
                  formik.values.Student.FirstName}
            </Card.Title>
            <p>
              I/We have applied for permission to attend a school in the Travis
              USD under the procedures set forth in Education Code 46600-46610.
              If the district approves such a request, it will be approved under
              the following conditions:
            </p>
            <ul>
              <li>
                <u>
                  <strong>Academics:</strong>
                </u>{" "}
                Maintain satisfactory grades/credits at all times, during each
                grading period, remain on track to graduate on schedule.
                Deficiency in credits would be a reason to discontinue an IDA.
              </li>
              <li>
                <u>
                  <strong>Attendance:</strong>
                </u>{" "}
                Maintain a consistent pattern of attendance and punctuality as
                required by school district policy and individual school
                requirements, including those set forth in the student
                handbooks. Truancy or excessive absences or tardies would be a
                reason to discontinue an IDA.
              </li>
              <li>
                <u>
                  <strong>Behavior:</strong>
                </u>{" "}
                Follow all school district and individual school rule and class
                guidelines, including any set forth in student handbooks. Any
                pattern of inappropriate behavior would be a reason to
                discontinue the IDA. Any suspension or expulsion would be a
                reason to discontinue an IDA.
              </li>
              <li>
                <u>
                  <strong>Transportation:</strong>
                </u>{" "}
                The parent/student will be responsible for providing her/his own
                transportation to and from school and must arrive at and depart
                from school according to the time schedules outlined by the
                school in their student handbook.
              </li>
              <li>
                <u>
                  <strong>School of Attendance:</strong>
                </u>{" "}
                The district reserves the right to transfer IDA students between
                schools if space is not available at a given site.
              </li>
              <li>
                <u>
                  <strong>Program Changes:</strong>
                </u>{" "}
                Any substantive change to the child's program would require that
                the IDA be reviewed to determine space availability. Should a
                student require a program change, the District may rescind the
                IDA if the program required to support the student is impacted.
              </li>
            </ul>
            <InitialField
              name="Applicant.ConfirmTerms"
              disabled={disabled}
              label={
                <p>
                  <i
                    style={{ color: "red" }}
                    className="fa fa-exclamation-triangle"
                  />
                  &nbsp;
                  <strong>
                    I/We (Parent and student) have read the above terms and
                    conditions and agree that I/we are bound by them and are
                    expected to meet all of the above criteria. I/We understand
                    that the permission of the student to attend school in
                    Travis USD will be revoked for failure to meet the
                    requirements set forth herein.
                  </strong>
                </p>
              }
            />
            <InitialField
              name="Applicant.ConfirmApplication"
              disabled={disabled}
              label={
                <p>
                  <i
                    style={{ color: "red" }}
                    className="fa fa-exclamation-triangle"
                  />
                  &nbsp;
                  <strong>
                    I/We (Parent and Student) declare under penalty of perjury
                    under the laws of the State of California that all of the
                    information on this application is true and correct and I/we
                    understand that any willfully inaccurate information
                    provided in this application will be grounds for immediate
                    denial or revocation of the Inter District Attendance
                    Agreement.
                  </strong>
                </p>
              }
            />
            <InitialField
              name="Applicant.ConfirmEnrollmentDeadline"
              disabled={disabled}
              label={
                <p>
                  <i
                    style={{ color: "red" }}
                    className="fa fa-exclamation-triangle"
                  />
                  &nbsp;
                  <strong>
                    I/We understand that once notified of approval, online
                    enrollment must be completed within 48 hours, including
                    required documents or the IDA application will be wait
                    listed. Also, the preferred school is not guaranteed and is
                    subject to change even after IDA is approved.
                  </strong>
                </p>
              }
            />
            <p>
              By entering your names below you have fully read and agree to this
              Agreement for Inter District Attendance in Travis Unified School
              District. Both you (the Parent/Guardian submitting this
              application) and the student must enter names below to acknowledge
              this agreement.
            </p>
            <Row>
              <Col xs={12} sm={6}>
                <Field
                  required
                  label="Parent/Guardian Signature"
                  name="InterdistrictAttendanceAgreement.GuardianSignature"
                  disabled={disabled}
                  placeholder="Your Name"
                  className="input-signature"
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  required
                  label="Student Signature"
                  name="InterdistrictAttendanceAgreement.StudentSignature"
                  disabled={disabled}
                  placeholder="Student Name"
                  className="input-signature"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InterdistrictAgreement;
