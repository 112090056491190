import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import "scss/InterdistrictApplicationStats.scss";

type Props = {
  applications: any;
};

type PieData = {
  name: string;
  value: number;
  percent?: number;
};

type GradeBarData = {
  grade: string;
  new: number;
  renewal: number;
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const RADIAN = Math.PI / 180;

function SchoolCode(school: string) : number{
  switch (school) {
    case "Cambridge":
    case "Cambridge Elementary":
    case "Cambridge Elem":
      return 5;
    case "Center":
    case "Center Elementary":
    case "Center Elem":
      return 1;
    case "Foxboro":
    case "Foxboro Elementary":
    case "Foxboro Elem":
      return 6;
    case "Scandia":
    case "Scandia Elementary":
    case "Scandia Elem":
      return 3;
    case "Travis":
    case "Travis Elementary":
    case "Travis Elem":
      return 2;
    case "Golden West":
    case "GW":
    case "GoldenWest":
    case "Golden West Middle":
    case "Golden West Middle School":
      return 4;
    case "Vanden":
    case "Vanden High":
      return 21;
    case "TEC":
    case "Travis Education Center":
      return 23;
    default:
      return 0;
  }
}

const renderCustomizedPieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  payload,
  ...props
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + 0.85 * radius * Math.cos(-midAngle * RADIAN);
  const y = cy + 0.85 * radius * Math.sin(-midAngle * RADIAN);
  const lx = cx + 2.65 * radius * Math.cos(-midAngle * RADIAN);
  const ly = cy + 2.65 * radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <text
        x={lx}
        y={ly}
        fill="black"
        textAnchor={lx > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {payload.name} - {payload.value}
      </text>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </>
  );
};

const renderCustomizedBarLabel = ({
  x,
  y,
  width,
  height,
  value,
  ...props
}: any) => {
  // console.log('Bar Label Props', props, x, y, width, height, value);
  if (value === 0) return <></>;
  return (
    <g>
      <text
        x={x + width / 2}
        y={height / 2 + y}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: any;
  label?: string;
};

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return <div>{label}</div>;
  } else return null;
};

const InterdistrictApplicationStats = ({ applications, ...props }: Props) => {
  const [appsByGradeWidth, setAppsByGradeWidth] = React.useState<number>(null!);
  const appsByGradeColRef = React.createRef<any>();
  console.log("Ref: ", appsByGradeColRef);
  console.log("Applciations", applications);
  let appsByStatus: Array<PieData> = [];
  let appsByRenewal: Array<PieData> = [
    { name: "New", value: 0 },
    { name: "Renewal", value: 0 },
  ];
  let appsByGrade: Array<GradeBarData> = [
    { grade: "K", new: 0, renewal: 0 },
    { grade: "1", new: 0, renewal: 0 },
    { grade: "2", new: 0, renewal: 0 },
    { grade: "3", new: 0, renewal: 0 },
    { grade: "4", new: 0, renewal: 0 },
    { grade: "5", new: 0, renewal: 0 },
    { grade: "6", new: 0, renewal: 0 },
    { grade: "7", new: 0, renewal: 0 },
    { grade: "8", new: 0, renewal: 0 },
    { grade: "9", new: 0, renewal: 0 },
    { grade: "10", new: 0, renewal: 0 },
    { grade: "11", new: 0, renewal: 0 },
    { grade: "12", new: 0, renewal: 0 },
  ];
  for (let i = 0; i < applications.length; i++) {
    let foundStatus = false;
    for (let j = 0; j < appsByStatus.length; j++) {
      if (appsByStatus[j].name === applications[i].ApplicationStatus) {
        appsByStatus[j].value++;
        foundStatus = true;
        break;
      }
    }

    if (!foundStatus) {
      appsByStatus.push({
        name: applications[i].ApplicationStatus,
        value: 1,
      });
    }

    if (
      applications[i].ReasonNewStudent === true ||
      applications[i].ReasonNewStudent === "true"
    ) {
      appsByRenewal[0].value++;
      appsByGrade[applications[i].StudentIncomingGrade].new++;
    } else {
      appsByRenewal[1].value++;
      appsByGrade[applications[i].StudentIncomingGrade].renewal++;
    }
  }

  const schoolGrades: { [index: string]: number[] } = {
    Cambridge: [0, 1, 2, 3, 4, 5, 6],
    Center: [0, 1, 2, 3, 4, 5, 6],
    Foxboro: [0, 1, 2, 3, 4, 5, 6],
    Scandia: [0, 1, 2, 3, 4, 5, 6],
    Travis: [0, 1, 2, 3, 4, 5, 6],
    "Golden West": [7, 8],
    Vanden: [9, 10, 11, 12],
    TEC: [9, 10, 11, 12],
  };

  for (let i = 0; i < appsByStatus.length; i++) {
    appsByStatus[i].percent = appsByStatus[i].value / applications.length;
  }
  // console.log("appsByStatus: ", appsByStatus, applications);

  React.useEffect(() => {
    console.log(
      "Setting apps by grade width",
      appsByGradeColRef.current.clientWidth
    );
    setAppsByGradeWidth(appsByGradeColRef.current.clientWidth);
  }, [appsByGradeColRef]);
  return (
    <>
      <Card className="singlepage">
        <Card.Header>
          <Card.Title className="center">
            {applications.length} Total Applications
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={6}>
              <PieChart width={appsByGradeWidth / 2} height={300}>
                <Pie
                  data={appsByStatus}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  nameKey="name"
                  outerRadius={75}
                  label={renderCustomizedPieLabel}
                >
                  {appsByStatus.map((entry: PieData, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            </Col>
            <Col xs={6}>
              <PieChart width={appsByGradeWidth / 2} height={300}>
                <Pie
                  data={appsByRenewal}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  nameKey="name"
                  outerRadius={75}
                  label={renderCustomizedPieLabel}
                >
                  {appsByRenewal.map((entry: PieData, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col ref={appsByGradeColRef}>
              <BarChart
                width={appsByGradeWidth}
                height={300}
                data={appsByGrade}
                margin={{
                  top: 5,
                  right: 50,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="grade" />
                <YAxis />
                <Bar
                  dataKey="new"
                  label={renderCustomizedBarLabel}
                  stackId="grade"
                  fill={COLORS[0]}
                />
                <Bar
                  dataKey="renewal"
                  label={renderCustomizedBarLabel}
                  stackId="grade"
                  fill={COLORS[1]}
                />
                <Legend />
              </BarChart>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Counts per School / Grade</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table size="sm" striped bordered className="highlightHoverRow">
            <thead>
              <tr>
                <th rowSpan={2}>School</th>
                <th rowSpan={2}>Grade</th>
                <th colSpan={2}>IEP/504</th>
                <th colSpan={2}>Active Duty Military</th>
                <th colSpan={2}>
                  IEP/504 <u>and</u> ADM
                </th>
              </tr>
              <tr>
                <th>Pending</th>
                <th>Approved</th>
                <th>Pending</th>
                <th>Approved</th>
                <th>Pending</th>
                <th>Approved</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(schoolGrades).map((k: string) => (
                <>
                  {schoolGrades[k].map((gr: number, i: number) => {
                    let SpedIEPPending = 0;
                    let SpedIEPApproved = 0;
                    let MilitaryPending = 0;
                    let MilitaryApproved = 0;
                    let BothPending = 0;
                    let BothApproved = 0;

                    for (let i = 0; i < applications.length; i++) {
                      console.log(applications[i].School);  
                      if (applications[i].StudentIncomingGrade !== gr || SchoolCode(k) !== applications[i].School) continue;

                      if (
                        ["Pending", "Applied"].includes(
                          applications[i].ApplicationStatus
                        )
                      ) {
                        if (applications[i].ReasonSpedIEP) {
                          SpedIEPPending++;
                        }
                        if (applications[i].ReasonActiveDutyMilitary) {
                          MilitaryPending++;
                        }
                        if (
                          applications[i].ReasonSpedIEP &&
                          applications[i].ActiveDutyMilitary
                        ) {
                          BothPending++;
                        }
                      } else if (
                        ["Approved", "Completed"].includes(
                          applications[i].ApplicationStatus
                        )
                      ) {
                        if (applications[i].ReasonSpedIEP) {
                          SpedIEPApproved++;
                        }
                        if (applications[i].ReasonActiveDutyMilitary) {
                          MilitaryApproved++;
                        }
                        if (
                          applications[i].ReasonSpedIEP &&
                          applications[i].ActiveDutyMilitary
                        ) {
                          BothApproved++;
                        }
                      }
                    }
                    return (
                      <tr>
                        {i === 0 ? (
                          <th rowSpan={schoolGrades[k].length}>{k}</th>
                        ) : (
                          <></>
                        )}
                        <th>{gr === 0 ? "K" : gr}</th>
                        <td className={SpedIEPPending > 0 ? "" : "muted"}>
                          {SpedIEPPending}
                        </td>
                        <td className={SpedIEPApproved > 0 ? "" : "muted"}>
                          {SpedIEPApproved}
                        </td>
                        <td className={MilitaryPending > 0 ? "" : "muted"}>
                          {MilitaryPending}
                        </td>
                        <td className={MilitaryApproved > 0 ? "" : "muted"}>
                          {MilitaryApproved}
                        </td>
                        <td className={BothPending > 0 ? "" : "muted"}>
                          {BothPending}
                        </td>
                        <td className={BothApproved > 0 ? "" : "muted"}>
                          {BothApproved}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default InterdistrictApplicationStats;
