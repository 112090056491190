import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Col, Container } from "react-bootstrap";
import Models, { InterdistrictApplication as IDA } from "tusd-common/models";
import FormikSessionStore from "../../../components/forms/FormikSessionStore";
import InterdistrictAdditionalQuestions from "./InterdistrictAdditionalQuestions";
import InterdistrictAgreement from "./InterdistrictAgreement";
import InterdistrictApplicantInfo from "./InterdistrictApplicantInfo";
import InterdistrictEnrollmentInfo from "./InterdistrictEnrollmentInfo";
import InterdistrictGuardianInfo from "./InterdistrictGuardianInfo";
import InterdistrictInstructions from "./InterdistrictInstructions";
import InterdistrictReasonForRequest from "./InterdistrictReasonForRequest";
import InterdistrictRequestInfo from "./InterdistrictRequestInfo";
import InterdistrictSiblings from "./InterdistrictSiblings";
import InterdistrictStudentInfo from "./InterdistrictStudentInfo";
import InterdistrictSubmit from "./InterdistrictSubmit";
import { CSSTransition } from "react-transition-group";
import ScrollToError from "components/forms/ScrollToError";
import ConfirmButton from "components/ConfirmButton";
import StartOverButton from "components/forms/StartOverButton";
import InterdistrictAdminReview from "./Review/InterdistrictAdminReview";
import ApplicationDocuments from "./Review/ApplicationDocuments";

type Props = {
  isAdmin?: boolean;
  applicationid?: number;
  initialData?: IDA;
};

const InterdistrictApplication = (props: Props) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [initial, setInitial] = useState<IDA>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [adminCanPrint, setAdminCanPrint] = useState<boolean>(false);
  const [adminCanPrintMsg, setAdminCanPrintMsg] = useState<React.ReactNode>(null!);
  const [message, setMessage] = useState<React.ReactNode>("");
  const [messageType, setMessageType] = useState<
    "danger" | "warning" | "success" | "primary" | "secondary"
  >("secondary");
  const formRef = useRef(null);
  const alertRef = useRef(null);

  useEffect(() => {
    if (props.isAdmin) {
      setInitial(props.initialData);
      console.log("Initial Data", props.initialData);
      setDisabled(true);
      setIsMounted(true);
      return;
    }

    let submitted = sessionStorage.getItem(
      "InterdistrictApplication_Submitted"
    );

    if (submitted === "true") {
      setSubmitted(true);
      setDisabled(true);
    }

    let jsonCache;
    setInitial({
      ...Models.IDAApplicationEmpty,
      Guardians: [],
      Siblings: [],
    });

    setIsMounted(true);
  }, []);

  const onSubmitForm = async (args: any) => {
    setDisabled(true);
    setSubmitting(true);
    let data = { ...args };
    let res;
    try {
      res = await axios.post("/api/applications/interdistrict", { data });
      if (res && res.data && res.data.status && res.data.status === "Success") {
        setSubmitted(true);
        sessionStorage.setItem("InterdistrictApplication_Submitted", "true");
        setMessageType("success");
        setMessage(
          res.data.message || (
            <span>
              Your application has been submitted successfully. Please{" "}
              <a
                href="#print"
                onClick={(e) => {
                  e.preventDefault();
                  window.print();
                }}
              >
                print
              </a>{" "}
              this page for your records.
            </span>
          )
        );
      } else {
        setSubmitted(false);
        setDisabled(false);
      }
    } catch (e) {
      setSubmitted(false);
      setDisabled(false);
      setMessageType("danger");
      setMessage(
        "There was an error submitting your application. Please try again. If the problem persists, please try again later."
      );
    } finally {
      setSubmitting(false);
    }
  };

  const resetForm = () => {
    setMessage("");
    setSubmitted(false);
    setDisabled(false);
    sessionStorage.setItem("InterdistrictApplication_Submitted", "false");
  };

  if (!isMounted) return <>Loading...</>;

  return (
    <Container>
      <Col></Col>
      {isMounted && (
        <Col>
          <Formik
            validationSchema={Models.IDAApplication}
            validateOnBlur={true}
            validateOnChange={true}
            validateOnMount={false}
            onSubmit={onSubmitForm}
            initialValues={{ ...initial }}
          >
            <Col>
              {props.isAdmin && (
                <InterdistrictAdminReview
                  applicationid={props.applicationid}
                  canPrint={adminCanPrint}
                  canPrintMsg={adminCanPrintMsg}
                />
              )}
              <h1>
                Application for Interdistrict Attendance at Travis Unified
                School District
              </h1>
              {!props.isAdmin && (
                <InterdistrictInstructions disabled={disabled} />
              )}
              {!props.isAdmin && (
                <Alert className="hidden-print" variant="secondary">
                  <StartOverButton resetForm={resetForm} /> If you want to start
                  over, click this button. Otherwise proceed to the next section
                  to begin filling out your application.
                </Alert>
              )}
              <InterdistrictApplicantInfo disabled={disabled} />
              <InterdistrictStudentInfo disabled={disabled} />
              <InterdistrictRequestInfo disabled={disabled} />
              <InterdistrictEnrollmentInfo disabled={disabled} />
              <InterdistrictReasonForRequest disabled={disabled} />
              <InterdistrictGuardianInfo disabled={disabled} />
              <InterdistrictSiblings disabled={disabled} />
              <InterdistrictAdditionalQuestions disabled={disabled} />
              <InterdistrictAgreement disabled={disabled} />

              <CSSTransition
                in={message !== ""}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <Alert variant={messageType}>{message}</Alert>
              </CSSTransition>

              <InterdistrictSubmit
                disabled={disabled}
                submitted={submitted}
                submitting={submitting}
                clearMessage={() => {
                  setMessage("");
                }}
                resetForm={resetForm}
              />
              {!props.isAdmin && (
                <>
                  <ScrollToError />
                  <FormikSessionStore name="InterdistrictApplication" />
                </>
              )}
              {/* {props.isAdmin && (
                <ApplicationDocuments
                  onReady={() => {
                    setAdminCanPrint(true);
                  }}
                />
              )} */}

              {/* <Button
                onClick={() => {
                  setSubmitted(false);
                  setDisabled(false);
                  setMessage("");
                }}
              >
                Enable
              </Button> */}
            </Col>
          </Formik>
        </Col>
      )}
    </Container>
  );
};

export default InterdistrictApplication;
