import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "../scss/Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <p className="text-center">Reaching beyond the boundaries to build a community of learners</p>
        <hr />
        <Row>
          <Col lg={4}>
            <a href="https://www.travisusd.org/domain/30">TUSD Student Services</a>
          </Col>
          <Col lg={4}>
            <a href="https://travisusd.org">District Office</a>
          </Col>
          <Col lg={4}>
            <a href="https://solanocoe.net/">Solano County Office of Education</a>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="text-center">
              <span>Travis Unified School District</span>
              <span className="horiz-separator" />
              <span>2751 De Ronde Drive, Fairfield, CA 94533</span>
              <span className="horiz-separator" />
              <span>(707) 437-4604</span>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
