import axios from "axios";
import React, { useEffect } from "react";

type AuthUser = {
  username: string;
  level: string;
}

interface AuthContextType {
  user: AuthUser | null;
  login: (
    username: string,
    password: string,
    callback: (success: boolean, message?: string) => void
  ) => void;
  azureLogin: (from: string) => void;
  logout: (callback: VoidFunction) => void;
}
export const AuthContext = React.createContext<AuthContextType>(null!);

type Props = {
  children: React.ReactNode;
};
const AuthProvider = (props: Props) => {
  const [user, setUser] = React.useState<AuthUser | null>(null);
  const [mounted, setMounted] = React.useState<boolean>(false);

  useEffect(() => {
    axios.get("/login")
      .then(res => {
        if (res.data && res.data.username && res.data.level) {
          console.log("Logged in!")
          setUser({
            username: res.data.username,
            level: res.data.level
          });
        }
        setMounted(true);
      })
  }, [])

  let login = (
    user: string,
    pass: string,
    callback: (success: boolean, message?: string) => void
  ) => {
    const loginwindow = window.open("/login/openid?redirect","Login", "new");
    const timer = setInterval(() => {
      if (loginwindow?.closed) {
        clearInterval(timer);
        callback(true);
      }
    }, 250)
    return;
  };

  let azureLogin = (from: string) => {
    window.location.replace(`/login/openid?redirect=${from}`)
  }

  const logout = (callback: VoidFunction) => {
    axios.get("/logout").then((res) => {
      setUser(null);
      callback();
    });
  };

  const value = { user, login, logout, azureLogin };

  if (!mounted) return <></>;
  
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;
