import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router";

function Home() {
  const navigate = useNavigate();

  return (
    <Row>
      <Col>
        <h1>TUSD Student Services Applications</h1>
        <p>
          Welcome to the Travis Unified School District Student Services
          Applications website.
        </p>
        <p>
          Using this website you may submit an electronic application to the
          Student Services department. Applications will be reviewed for
          completeness and we may reach out to confirm details or collect more
          information.
        </p>
        <p>
          As part of the application process you may be required to upload some
          relevant documents. Please make sure to have electronic copies of all
          relevant documents ready before starting an application. A list of
          required documents is available on each application page.
        </p>
        <p>
          You will also be asked to type in your name or initials to acknowledge
          several terms, conditions, or application details.
        </p>
        <p>
          <b>
            Applications are not guaranteed to be accepted. Please maintain your
            child's current enrollment (or enroll your child in their resident
            school) until otherwise notified.
          </b>
        </p>
        <Row>
          <Col sm={6}>
            <Card>
              <Card.Header>
                <Card.Title>Inter-District Attendance Agreement</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  Apply for an Inter District Attendance Agreement for your
                  child who does not live within Travis USD boundaries.
                </p>
                <Button onClick={() => navigate("/Apply/Interdistrict")}>
                  Apply
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Card>
              <Card.Header>
                <Card.Title>Intra-District Attendance Agreement</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  Electronic Intra-District Attendance Agreement is not yet
                  available.
                </p>
                <p>
                  Please fill out a paper form from the{" "}
                  <a href="https://www.travisusd.org/Page/5974">
                    Student Services website
                  </a>
                  .
                </p>
                <Button disabled variant="secondary">
                  Coming soon...
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Card>
              <Card.Header>
                <Card.Title>Shared Residency Affidavit</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  Electronic Shared Residency Affidavit is not yet available.
                </p>
                <p>
                  Please fill out a paper form from the{" "}
                  <a href="https://www.travisusd.org/Page/5974">
                    Student Services website
                  </a>
                  .
                </p>
                <Button disabled variant="secondary">
                  Coming soon...
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Card>
              <Card.Header>
                <Card.Title>Caregiver Authorization Affidavit</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>
                  Electronic Caregiver Authorization Affidavit is not yet
                  available.
                </p>
                <p>
                  Please fill out a paper form from the{" "}
                  <a href="https://www.travisusd.org/Page/5974">
                    Student Services website
                  </a>
                  .
                </p>
                <Button disabled variant="secondary">
                  Coming soon...
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

Home.propTypes = {};

export default Home;
