import StateSelector from "../../../components/forms/StateSelector";
import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Field from "../../../components/forms/Field";
import FileField from "components/forms/FileField";
import { InterdistrictApplication } from "tusd-common";
import { useFormikContext } from "formik";
type Props = {
  disabled?: boolean;
};

const InterdistrictEnrollmentInfo = (props: Props) => {
  const formik = useFormikContext<InterdistrictApplication>();
  const disabled = props.disabled || false;
  return (
    <Card>
      <Card.Header>
        <Card.Title>Student Enrollment Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <Col>
          <p>
            Please provide information about this student's most recent school
            enrollment.
          </p>
          <hr />
          <Row>
            <Col sm={4}>
              <Field
                required
                label="Most Recent School"
                type="text"
                name="Student.MostRecentSchoolName"
                disabled={disabled}
              />
            </Col>
            <Col sm={4}>
              <Field required
                label="Most Recent School City"
                type="text"
                name="Student.MostRecentSchoolCity"
                disabled={disabled}
              />
            </Col>
            <Col sm={4}>
              <StateSelector required
                label="Most Recent School State"
                name="Student.MostRecentSchoolState"
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </Card.Body>
    </Card>
  );
};

export default InterdistrictEnrollmentInfo;
