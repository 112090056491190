import React from "react";
import PropTypes from "prop-types";
import FilterHeader from "./FilterHeader";
import Table from "react-bootstrap/Table";
import "../scss/DataTable.scss";
import Pager from "./Pager";
import { DataControls } from "./DataController";

export interface Header {
  field: string;
  label: string;
  sortable?: boolean;
  filterable?: boolean;
  searchable?: boolean;
  filters?: Array<{value: string; label: string}>;
}

interface DataTableProps<T> {
  headers: Array<Header>;
  row: React.FC<T>;
  data?: Array<T>;
  controls?: DataControls;
}

function DataTable<T>({ headers, data, row, controls, ...props } : DataTableProps<T>) {
  return (
    <React.Fragment>
      <Pager page={controls?.page} onSelectPage={controls?.onPage} />
      <Table striped size="sm" responsive className="DataTable" {...props}>
        <thead>
          <tr>
            {headers.map((e, i) => (
              <FilterHeader
                key={i}
                field={e}
                controls={controls}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.map((e, i) => {
            return React.createElement(row, e);
            // return React.isValidElement(row) ? React.cloneElement(row, { ...e, key: i }) : null
          }
          )}
          {!Array.isArray(data) || data.length === 0 && (
            <tr></tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

DataTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  row: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  controls: PropTypes.object,
};

export default DataTable;
