import axios from "axios";
import { setDefaultResultOrder } from "dns/promises";
import React, { useEffect, useState } from "react";

enum ECRUDResult {
  Success = "Success",
  Invalid = "Invalid",
  Failure = "Failure",
  Unauthorized = "Unauthorized",
  Error = "Error",
}

interface CRUDResult {
  status: ECRUDResult;
  message?: string;
  data?: any;
}

const useApi = (url: string) => {
  const [result, setResult] = useState<CRUDResult>(null!);
  const [message, setMessage] = useState<string>(null!);
  const [error, setError] = useState<any>(null!);
  const [loading, setLoading] = useState<boolean>(true);
  const [refreshIndex, setRefreshIndex] = useState<number>(0);

  useEffect(() => {
    let cancelled = false;
    setLoading(true);
    axios
      .get<CRUDResult>(url)
      .then((res) => {
        if (cancelled) return;

        setLoading(false);
        setError(null!);
        setMessage(null!);

        if (res.data.status === ECRUDResult.Success) {
          setResult(res.data);
          if (res.data.message) setMessage(res.data.message);
          return;
        }

        if (res.data.message) {
          setMessage(message);
        }

        // If there is an error, clear the current data.
        if (
          res.data.status === ECRUDResult.Error ||
          res.data.status === ECRUDResult.Invalid ||
          res.data.status === ECRUDResult.Failure
        ) {
          setResult(null!);
          if (res.data.message) setError(res.data.message);
        }
      })
      .catch((err) => {});

    return () => {
      // Cancel the request;
      cancelled = true;
    };
  }, [url, refreshIndex]);

  return {
    data: result?.data,
    message,
    error,
    loading,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};

export default useApi;
