import ConfirmButton from "components/ConfirmButton";
import { useFormikContext } from "formik";
import React from "react";
import { IDAApplicationEmpty } from "tusd-common";

type Props = {
  resetForm: Function;
};

const StartOverButton = (props: Props) => {
  const formik = useFormikContext();
  return (
    <ConfirmButton
      variant="secondary"
      onClick={() => {
        formik.resetForm(IDAApplicationEmpty);
        props.resetForm && props.resetForm();
      }}
      confirmText="Are you sure you want to start over? This will delete everything and you will have to re-enter all data."
    >
      Start Over
    </ConfirmButton>
  );
};

export default StartOverButton;
