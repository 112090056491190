import React from "react";
import { Card } from "react-bootstrap";
import FormAdditionalQuestion from "./FormAdditionalQuestion";

type Props = {
  disabled?: boolean;
};

const InterdistrictAdditionalQuestions = (props: Props) => {
  const disabled = props.disabled || false;
  return (
    <Card>
      <Card.Header>
        <Card.Title>Additional Questions</Card.Title>
      </Card.Header>
      <Card.Body>
        <p>
          Please answer each of these additional questions. If you answer "Yes"
          to any, you must also answer when and where.
        </p>
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.PreviouslyAttendedTUSD"
          disabled={disabled}
          question={<strong>Has this child previously attended TUSD?</strong>}
        />
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.SpedIEP"
          disabled={disabled}
          question={
            <strong>
              Does this child have a Special Education-IEP / 504 plan?
            </strong>
          }
          file="Please upload a copy of this child's IEP or 504 plan."
        />
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.AttendanceProblems"
          disabled={disabled}
          question={
            <strong>Does this child have a history Attendance Problems?</strong>
          }
        />
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.AcademicPerformanceProblems"
          disabled={disabled}
          question={
            <strong>
              Does this child have a history of Academic Performance Problems?
            </strong>
          }
        />
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.GradeRepeated"
          disabled={disabled}
          question={<strong>Has this child repeated a grade?</strong>}
        />
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.Suspensions"
          disabled={disabled}
          question={
            <strong>
              Has this child been suspended from an academic program before?
            </strong>
          }
        />
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.Expulsions"
          disabled={disabled}
          question={
            <strong>
              Has this child been expelled from an academic program before?
            </strong>
          }
        />
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.AltEdPrograms"
          disabled={disabled}
          question={
            <span>
              <strong>
                Does this child participate in an Alternative Education Program
              </strong>{" "}
              <br />
              (Continuation School / County Day School / Opportunity Class)
            </span>
          }
        />
        <hr />
        <FormAdditionalQuestion
          required
          name="AdditionalQuestions.SportsOrExtracurricular"
          disabled={disabled}
          question={
            <strong>
              Has this child participated in sport(s) against other schools or
              extracurricular activities in the previous school year.
            </strong>
          }
        />
      </Card.Body>
    </Card>
  );
};

export default InterdistrictAdditionalQuestions;
