export function parseAcademicYear(year?: number | string): number {
  if (!year) return 0;
  if (typeof year === "number") {
    return year;
  }
  if (typeof year === "string") {
    if (year.match(/[0-9]{4}-[0-9]{4}/g)) {
      let yr = year.split("-");
      return parseInt(yr[1]);
    }
    if (year.match(/[0-9]{2}-[0-9]{2}/g)) {
      let yr = year.split("-");
      return 2000 + parseInt(yr[1]);
    }

    let num = parseInt(year);
    if (!isNaN(num)) return num;
  }
  // This should never happen.
  return 0;
}

export function printAcademicYear(year?: number | string) : string {
  let yr = parseAcademicYear(year);
  return `${yr-1}-${yr}`;
}

export function formatPhone(phone: number | string): string {
  if (typeof phone === "number") {
    let ph = "" + phone;
    return `(${ph.substr(0, 3)}) ${ph.substr(4, 6)}-${ph.substr(7)}`;
  }
  if (typeof phone === "string" && phone.match(/[0-9]{9}/g)) {
    return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6)}`;
  }
  return phone;
}

export function staticDate(date: Date | string) {
  let dt : Date;
  dt = new Date(date);
  dt = new Date(dt.getTime() + dt.getTimezoneOffset()*60*1000);
  return dt;
}

export function formatBirthdate(bd: Date | string) {
  
  let dt = staticDate(bd);
  const day = ("0" + dt.getDate()).slice(-2);
  const mon = ("0" + (dt.getMonth() + 1)).slice(-2);
  return `${dt.getFullYear()}-${mon}-${day}`
}

export function formatDate(date: Date | string) {
  let dt = new Date(date);
  return dt.toLocaleString();
}

export function formatGrade(grade?: number | string): string {
  if (typeof grade === "string") return grade;
  if (grade === -2) return "PS";
  if (grade === -1) return "TK";
  if (grade === 0) return "K";
  return "" + grade;
}

export function isTrue(val : boolean | string | number) {
  if (typeof val === "boolean") return val;
  if (typeof val === "string" && val.toLowerCase() === "true") return true;
  if (typeof val === "number" && val > 0) return true;
  return false;
}

export function schoolName(sc ?: number) {
  switch(sc) {
    case 0: return "";
    case 1: return "Center Elementary";
    case 2: return "Travis Elementary";
    case 3: return "Scandia Elementary";
    case 4: return "Golden West";
    case 5: return "Cambridge Elementary";
    case 6: return "Foxboro Elementary";
    case 21: return "Vanden";
    case 22: return "Community Day";
    case 23: return "TEC";
    case 25: return "Independent Study";
  default: return "";
  }
}