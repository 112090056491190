import React from 'react'

export default function NotFound() {
  return (
    <>
    <h1>404: Not Found</h1>
    <p>The page you requested could not be found.</p>
    </>
  )
}
