const AllStates = ['ZZ','AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'];
const AllStatuses = ['Applied', 'Approved', 'Waitlist', 'Pending', 'Completed'];
const AllTypes = ['Inter', 'Intra', 'SRA', 'Caregiver'];
const AllYears = ['2018-2019', '2019-2020', '2020-2021', '2021-2022', '2022-2023'];

module.exports = {
  AllStates,
  AllStatuses,
  AllTypes,
  AllYears
}
