import React from 'react'
import { Alert, Button, Form, InputGroup } from 'react-bootstrap'

type SaveParams = {
  oldValue: string;
  newValue: string;
  onSuccess: () => void;
  onFailure: (error?: string) => void
}

type Props = {
  label?: React.ReactNode;
  value: string;
  onChange?: (e: any) => void;
  onSave?: (params : SaveParams) => void;
  children?: React.ReactNode;
}

const SelectSave = (props: Props) => {
  const [value, setValue] = React.useState<string>(props.value);
  const [error, setError] = React.useState<string>(null!);
  const [saved, setSaved] = React.useState<boolean>(true);

  const handleChange = (e : any) => {
    console.log("New value: ", e.target.value, props.value);
    setValue(e.target.value);
    if (e.target.value !== props.value)
      setSaved(false);
    else
      setSaved(true);
    if (props.onChange) props.onChange(e);
  }

  const handleSave = () => {
    if (props.onSave) {
      props.onSave({
        oldValue: props.value,
        newValue: value,
        onSuccess: handleSuccess,
        onFailure: handleFailure,
      })
    } else {
      setSaved(true);
    }
  }

  const handleSuccess = () => {
    setSaved(true);
    setError("");
  }

  const handleFailure = (error?: string) => {

  }

  return (
    <>
      <InputGroup>
        {props.label && (
          <Form.Label>
            {props.label}
          </Form.Label>
        )}
        <Form.Select value={value} onChange={handleChange} className="form-select-noformik">
          {props.children}
        </Form.Select>
        {!saved && (
        <>
          <Button variant="success" onClick={handleSave}>
            <i className="fa fa-floppy-o" />
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              setValue(props.value);
              setSaved(true);
              setError("");
            }}
          >
            <i className="fa fa-undo" />
          </Button>
          {error && (
            <Alert variant="danger">{error}</Alert>
            )}
        </>
      )}
      </InputGroup>
    </>
  )
}

export default SelectSave