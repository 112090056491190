import axios from "axios";
import useAuth from "../hooks/auth/useAuth";
import React, { useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  //@ts-ignore TODO
  const from = location.state?.from?.pathname || "/Admin";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const SubmitForm = (e: any) => {
    e.preventDefault();
    auth.azureLogin(from);
  };

  return <Button onClick={SubmitForm}>Login with Azure</Button>;
}
