import React from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import Field from "../../../components/forms/Field";
import FileField from "../../../components/forms/FileField";
import CheckField from "../../../components/forms/CheckField";
import { InterdistrictApplication } from "tusd-common";
import SchoolSelector from "../../../components/forms/SchoolSelector";
import SelectField from "components/forms/SelectField";
import { isTrue } from "util/util";
import ValidationHelper from "components/forms/ValidationHelper";
import Textarea from "components/forms/Textarea";

type Props = {
  disabled?: boolean;
};

const InterdistrictReasonForRequest = (props: Props) => {
  const formik = useFormikContext<InterdistrictApplication>();
  return (
    <Card>
      <Card.Header>
        <Card.Title>Inter District Transfer Request</Card.Title>
      </Card.Header>
      <Card.Body>
        <p>Details about your Inter District Attendance Agreement Request.</p>
        <hr />
        <Row>
          <Col>
            <Form.Group>
              <SelectField
                label="Is this application for a new Interdistrict Attendance Agreement or a Renewal of a currently active Interdistrict Attendance Agreement?"
                name="Student.NewStudent"
                placeholder="Select Application Type"
                disabled={props.disabled}
              >
                <option value="true">
                  New Interdistrict Attendance Agreement
                </option>
                <option value="false">
                  Renewal of Interdistrict Attendance Agreement
                </option>
              </SelectField>
            </Form.Group>
          </Col>
        </Row>
        {isTrue(formik.values.Student.NewStudent) && (
          <Row>
            {formik?.values?.Student?.IncomingGrade > 0 && (
              <Col xs={12}>
                <FileField
                  required
                  label={
                    formik?.values?.Student?.IncomingGrade > 9
                      ? "Please upload a copy of this child's most recent Transcripts"
                      : "Please upload a copy of this child's most recent Grade Report"
                  }
                  name="Student.TranscriptsDoc"
                  disabled={props.disabled}
                />
              </Col>
            )}
            <Col xs={12} sm={4}>
              <SchoolSelector
                required
                name="Student.FirstChoiceSchool"
                label="First Choice School"
                disabled={props.disabled}
              />
            </Col>
            {formik?.values?.Student?.IncomingGrade < 7 && (
              <>
                <Col xs={12} sm={4}>
                  <SchoolSelector
                    name="Student.SecondChoiceSchool"
                    label="Second Choice School"
                    disabled={props.disabled}
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <SchoolSelector
                    name="Student.ThirdChoiceSchool"
                    label="Third Choice School"
                    disabled={props.disabled}
                  />
                </Col>
              </>
            )}
          </Row>
        )}
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                Check all applicable reasons for your Interdistrict Attendance
                Request.<span className="label-required">*</span>
              </Form.Label>
                <ValidationHelper name="Student.ReasonForRequest" />
              <CheckField
                label="Parent/Legal Guardian of the student is Active Duty Military"
                name="Student.GuardianActiveDutyMilitary"
                disabled={props.disabled}
                noValid
              />
              {formik.values.Student &&
                formik.values.Student.GuardianActiveDutyMilitary && (
                  <FileField
                    label="Please upload your documents."
                    name="Student.GuardianActiveDutyMilitaryDoc"
                    multiple
                    disabled={props.disabled}
                  />
                )}
              <CheckField
                label="Parent/Legal Guardian of the student is an employee of Travis USD"
                {...formik.getFieldProps("Student.GuardianTUSDEmployee")}
                disabled={props.disabled}
                noValid
              />
              {formik.values.Student &&
                formik.values.Student.GuardianTUSDEmployee && (
                  <FileField
                    label="Please upload a copy of your most recent pay stub."
                    name="Student.GuardianTUSDEmployeeDoc"
                    multiple
                    disabled={props.disabled}
                  />
                )}
              <CheckField
                label="Sibling of a student currently attending a Travis USD school."
                name="Student.SiblingAttendsTUSD"
                disabled={props.disabled}
                noValid
              />
              {!isTrue(formik.values.Student.NewStudent) && (
                <CheckField
                  label={
                    <span>
                      <b
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        Mid-Year
                      </b>{" "}
                      Recently moved out of Travis USD attendance boundaries and
                      would like to complete the trimester (elementary, semester
                      (secondary) or school year.
                    </span>
                  }
                  name="Student.MidYearMove"
                  disabled={props.disabled}
                  noValid
                />
              )}
              <Form.Group>
                <CheckField
                  label="Other"
                  name="Student.Other"
                  disabled={props.disabled}
                  noValid
                />
                {formik.values.Student?.Other && (
                  <Textarea
                    label="Other reasoning"
                    name="Student.OtherText"
                    disabled={props.disabled}
                    type="text"
                  />
                )}
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InterdistrictReasonForRequest;
