import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Field from "../components/forms/Field";
import FormSection from "../components/forms/FormSection";
import logo from "../img/tusd_web_logo_2.png";
import "../scss/MainContent.scss";
import PageRouter from "./PageRouter";

function MainContent() {
  return (
    <Container className="MainContent">
      <PageRouter />
    </Container>
  );
}

export default MainContent;
