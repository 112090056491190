import axios from "axios";
import ConfirmButton from "components/ConfirmButton";
import DataController, { SortOrder } from "components/DataController";
import DataTable from "components/DataTable";
import Loading from "components/Loading";
import useApi from "hooks/useApi";
import React from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";

type Props = {};

const AccessLevel = (level: string) => {
  switch (level) {
    case "A":
      return "Admin";
    case "W":
      return "Write";
    default:
      return "Read";
  }
};
type User = {
  Username: string;
  Name: string;
  AccessLevel: string;
  LastIP?: string;
  LastLogin?: Date;
  refresh?: Function;
};

const UsersListUser = (user: User) => {
  const DeleteUser = (e: any) => {
    console.log("Delete user");
    e?.preventDefault && e.preventDefault();
    axios.delete(`/api/users/${user.Username}`).then((res) => {
      user.refresh && user.refresh();
    })
  };

  return (
    <tr>
      <td>{user.Name}</td>
      <td>{user.Username}</td>
      <td>{AccessLevel(user.AccessLevel)}</td>
      <td>
        <ConfirmButton noButton onClick={DeleteUser}>
          <a style={{ color: "red" }} href="#">
            <i className="fa fa-trash" />
          </a>
        </ConfirmButton>
      </td>
    </tr>
  );
};

type UsersListProps = {
  users: Array<User>;
};
const UsersListHeaders = [
  {
    field: "Name",
    label: "Name",
    searchable: true,
    sortable: true,
  },
  {
    field: "Username",
    label: "Username",
    searchable: true,
    sortable: true,
  },
  {
    field: "AccessLevel",
    label: "Access",
    searchable: false,
    sortable: true,
    filterable: true,
    filters: [
      { label: "Admin", value: "A" },
      { label: "Write", value: "W" },
      { label: "Read", value: "R" },
    ],
  },
];
const UsersList = ({ users }: UsersListProps) => {
  if (!users) return <>No users found.</>;
  return (
    <DataController
      data={users}
      paged
      defaultSort={{ sortKey: "Username", sortOrder: SortOrder.ASC }}
    >
      <DataTable headers={UsersListHeaders} row={UsersListUser} />
    </DataController>
  );
};

const RegisterUser = ({ refresh }: { refresh: Function }) => {
  const [user, setUser] = React.useState<User>({
    Name: "",
    Username: "",
    AccessLevel: "R",
  });

  const [message, setMessage] = React.useState<string | null>(null);
  const [messageType, setMessageType] = React.useState<
    "success" | "danger" | "warning"
  >("danger");

  const RegisterUser = () => {
    if (
      !user ||
      user?.Name === "" ||
      user?.Username === "" ||
      user?.AccessLevel === ""
    ) {
      setMessage("Invalid user.");
      setMessageType("danger");
      return;
    }

    axios
      .post(`/api/users`, {
        ...user,
        Username:
          user.Username.replace(/@travisusd.org/gi, "") + "@travisusd.org",
        AccessLevel: AccessLevel(user.AccessLevel),
      })
      .then((res) => {
        if (res.data.status === "Success") {
          setMessage(
            `Successfully registered ${user.Name} <${user.Username.replace(
              /@travisusd.org/gi,
              ""
            )}@travisusd.org>`
          );
          setMessageType(`success`);
          refresh();
        } else {
          setMessage(
            `Failed to register ${user.Name} <${user.Username.replace(
              /@travisusd.org/gi,
              ""
            )}@travisusd.org>`
          );
          setMessageType(`danger`);
        }
      }).catch(res => {
        
        if (res?.response?.status === 500){
          setMessageType("danger");
          setMessage("You're not authorized to do that.");
        }
      });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <h1>Register new user:</h1>
        </Col>
        <Col lg={4}>
          <Form.Group>
            <Form.Label for="user-name">Name</Form.Label>
            <Form.Control
              id="user-name"
              type="text"
              value={user.Name || ""}
              placeholder="Name"
              onChange={(e) => {
                setUser({ ...user, Name: e.target.value });
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group>
            <Form.Label for="user-username">TUSD Email Address</Form.Label>
            <InputGroup>
              <Form.Control
                id="user-username"
                type="text"
                value={user.Username || ""}
                placeholder="Username"
                onChange={(e) => {
                  setUser({ ...user, Username: e.target.value });
                }}
              />
              <InputGroup.Text>@travisusd.org</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group>
            <Form.Label for="user-name">Access</Form.Label>
            <Form.Select
              id="user-accesslevel"
              value={user.AccessLevel || "R"}
              onChange={(e) => {
                setUser({ ...user, AccessLevel: e.target.value });
              }}
            >
              <option value="A">Admin</option>
              <option value="W">Write</option>
              <option value="R">Read</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={1}>
          <Form.Label for="save-btn">Save</Form.Label>
          <br />
          <Button id="save-btn" variant="success" onClick={RegisterUser}>
            <i className="fa fa-save" />
          </Button>
        </Col>
      </Row>
      {message && (
        <Row>
          <Col>
            <Alert
              variant={messageType}
              dismissible
              onClose={() => {
                setMessage("");
              }}
            >
              {message}
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};

const Users = (props: Props) => {
  const { data, loading, refresh } = useApi(`/api/users`);

  if (loading) return <Loading />;

  let users = data;
  for (let i = 0; i < users?.length; i++) {
    users[i].refresh = refresh;
  }

  return (
    <>
      <Row>
        <Col>
          <h1>Registered users:</h1>
          <UsersList users={users} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <RegisterUser refresh={refresh} />
        </Col>
      </Row>
    </>
  );
};

export default Users;
