import PropTypes from "prop-types";
import React from "react";
import { InputGroup } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import "../scss/FilterHeader.scss";
import { DataControls, Sort, SortOrder } from "./DataController";

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  active: boolean;
}

const CustomToggle = React.forwardRef<HTMLInputElement, CustomToggleProps>(
  ({ children, onClick, active }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={"label " + (active ? "active" : "")}
    >
      {children}
    </div>
  )
);

CustomToggle.displayName = "CustomToggle";

interface CustomMenuProps {
  field: string;
  searchable: boolean;
  sortable: boolean;
  onSearch?: (field: string) => (value: string) => void;
  getSearch?: (field: string) => string[] | string | undefined;
  onSort?: (field: string) => (sort: SortOrder) => void;
  getSort?: () => Sort;
  labelledBy: string;
  className: string;
  style: any;
  children: React.ReactNode;
}

const CustomMenu = React.forwardRef<HTMLInputElement, CustomMenuProps>(
  (
    {
      children,
      style,
      className,
      labelledBy,
      field,
      searchable,
      getSearch,
      sortable,
      onSearch,
      getSort,
      onSort,
    },
    ref
  ) => {
    const activeSort =
      getSort && (getSort().field === field ? getSort().order : SortOrder.NONE);
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labelledBy}
      >
        {searchable && (
          <InputGroup>
            <FormControl
              type="search"
              autoFocus
              placeholder="Search..."
              onChange={(e) => {
                if (onSearch) onSearch(field)(e.target.value);
              }}
              value={getSearch ? getSearch(field) : ""}
            />
            <div
              className="clearButton"
              onClick={() => {
                if (onSearch) onSearch(field)("");
              }}
            >
              <i className="fa fa-times" />
            </div>
          </InputGroup>
        )}
        {sortable && (
          <span className="sort-buttons">
            <a
              href="#ASC"
              onClick={() => {
                if (onSort) onSort(field)(SortOrder.ASC);
              }}
              className={activeSort === SortOrder.ASC ? "active" : undefined}
            >
              <i className="fa fa-sort-alpha-asc" />
            </a>
            <a
              href="#DESC"
              onClick={() => {
                if (onSort) onSort(field)(SortOrder.DESC);
              }}
              className={activeSort === SortOrder.DESC ? "active" : undefined}
            >
              <i className="fa fa-sort-alpha-desc" />
            </a>
          </span>
        )}
        {children && <ul className="list-unstyled">{children}</ul>}
      </div>
    );
  }
);

CustomMenu.displayName = "CustomMenu";

/* What do I need to pass to this?
 * field
 * label
 * onFilter
 * onSort
 * onSearch
 * filters -- determine what filters are available
 * filtered -- determine what is filtered.
 * searched -- value for the search box...
 */

function TH(props: any) {
  return <th {...props}>{props.children}</th>;
}

TH.propTypes = {
  children: PropTypes.any,
};

interface SortIconProps {
  field: string;
  sortKey?: string;
  sortOrder?: SortOrder;
}
function SortIcon({ field, sortKey, sortOrder }: SortIconProps) {
  let classes = ["fa"];
  if (
    sortKey === field &&
    (sortOrder === SortOrder.ASC || sortOrder === SortOrder.DESC)
  ) {
    classes.push("fa-sort-alpha-" + sortOrder.toLowerCase());
    classes.push("active");
  } else classes.push("fa-bars");
  return <i className={classes.join(" ")} />;
}

SortIcon.propTypes = {
  field: PropTypes.string,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
};

interface FilterHeaderProps {
  field: Field;
  controls?: DataControls;
}

interface Field {
  field: string;
  label: string;
  sortable?: boolean;
  filterable?: boolean;
  searchable?: boolean;
  filters?: Array<{ value: string; label: string }>;
}

function FilterHeader({ field, controls, ...props }: FilterHeaderProps) {
  const currentFilter = controls?.getFilter(field.field);
  const currentSearch = controls?.getSearch(field.field);
  const active =
    (currentFilter && currentFilter.length > 0) ||
    (currentSearch && currentSearch.length > 0) ||
    controls?.sortKey === field.field
      ? true
      : false;
  if (!field.sortable && !field.filterable && !field.searchable) {
    return <TH>{field.label}</TH>;
  }
  return (
    <Dropdown
      as={TH}
      className="FilterHeader"
      autoClose="outside"
      onSelect={(e) => {
        if (e) controls?.onFilter(field.field)(e);
      }}
    >
      <Dropdown.Toggle as={CustomToggle} active={active} className="toggle">
        {field.label}
        {field.sortable && (
          <SortIcon
            field={field.field}
            sortKey={controls?.sortKey}
            sortOrder={controls?.sortOrder}
          />
        )}

        {field.filterable && (
          <i
            className={
              "fa fa-filter " +
              (currentFilter && currentFilter.length > 0 ? "active" : "")
            }
          />
        )}

        {field.searchable && (
          <i
            className={
              "fa fa-search " +
              (currentSearch && currentSearch?.length > 0 ? "active" : "")
            }
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomMenu}
        {...props}
        field={field.field}
        searchable={field.searchable ?? false}
        sortable={field.sortable ?? false}
        onSearch={controls?.onSearch}
        onSort={controls?.onSort}
        getSort={controls?.getSort}
        getSearch={controls?.getSearch}
        labelledBy={field.label}
      >
        {field.filterable &&
          field.filters &&
          field.filters.map((filter) => (
            <Dropdown.Item key={filter.value} eventKey={filter.value}>
              {/* {console.log('DropdownItem GetFilter', field.field, controls.getFilter(field.field))} */}
              <i
                className={
                  "fa fa-" +
                  (controls?.getFilter(field.field)?.includes(filter.value)
                    ? "check-square-o"
                    : "square-o")
                }
              />
              {filter.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FilterHeader;
