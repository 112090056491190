import React from "react";
import { Form } from "react-bootstrap";
import Field from "./Field";

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
};

const GradeSelector = (props: Props) => {
  return (
    <Field label={props.label} name={props.name} disabled={props.disabled}>
      {({ label, name, disabled, isValid, isInvalid, field, meta }) => (
        <>
          <Form.Label>{label}{props.required && (<span className="label-required">*</span>)}</Form.Label>
          <Form.Select
            {...field}
            isValid={isValid}
            isInvalid={isInvalid}
            disabled={disabled}
          >
            <option value="">Select Grade</option>
            <option value="0">Kindergarten</option>
            <option value="1">1st Grade</option>
            <option value="2">2nd Grade</option>
            <option value="3">3rd Grade</option>
            <option value="4">4th Grade</option>
            <option value="5">5th Grade</option>
            <option value="6">6th Grade</option>
            <option value="7">7th Grade</option>
            <option value="8">8th Grade</option>
            <option value="9">9th Grade</option>
            <option value="10">10th Grade</option>
            <option value="11">11th Grade</option>
            <option value="12">12th Grade</option>
          </Form.Select>
        </>
      )}
    </Field>
  );
};

export default GradeSelector;
