import React, { PropsWithChildren, useState } from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
  variant?: "primary" | "secondary" | "danger" | "warning" | "success" | "info";
  size?: "sm" | "lg";
  confirmTitle?: React.ReactNode;
  confirmText?: React.ReactNode;
  onClick: Function;
  noButton?: boolean;
};

const ConfirmButton = (props: PropsWithChildren<Props>) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <>
      {props.noButton ? (
        <>
        {/*@ts-ignore*/}
        {React.cloneElement(props.children, {onClick: () => {setShow(true)}})}
        </>
      ) : (
        <Button
          variant={props.variant || "primary"}
          size={props.size}
          onClick={() => {
            setShow(true);
          }}
        >
          {props.children}
        </Button>
      )}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{props.confirmTitle || "Are you sure?"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.confirmText || "Are you sure?"}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          &nbsp;
          <Button
            variant="success"
            onClick={() => {
              setShow(false);
              props.onClick();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmButton;
