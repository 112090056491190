import Field from "../../../components/forms/Field";
import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import FormAcademicYearSelector from "./FormAcademicYearSelector";

type Props = {
  isAdmin?: boolean;
  disabled?: boolean;
};

const InterdistrictApplicantInfo = (props: Props) => {
  const disabled = props.disabled || false;
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          {props.isAdmin ? "Applicant" : "Your"} Information
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <p>
          Please enter your own information in this section. We will collect
          your child's information later in the application.
        </p>
        <hr />
        <Row>
          <Col sm={4}>
            <Field
              required
              label="Your Last Name"
              type="text"
              name="Applicant.LastName"
              disabled={disabled}
            />
          </Col>
          <Col sm={4}>
            <Field
              required
              label="Your First Name"
              type="text"
              name="Applicant.FirstName"
              disabled={disabled}
            />
          </Col>
          <Col sm={4}>
            <Field
              required
              label="Your Initials"
              type="text"
              name="Applicant.Initials"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Field
              required
              label="Your Phone Number"
              type="text"
              name="Applicant.Phone"
              disabled={disabled}
            />
          </Col>
          <Col sm={4}>
            <Field
              required
              label="Your Email Address"
              type="text"
              name="Applicant.Email"
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormAcademicYearSelector
              required
              name="Applicant.AcademicYear"
              label="What academic year is this application for?"
              disabled={disabled}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InterdistrictApplicantInfo;
