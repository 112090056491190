import React from 'react'
import { Breadcrumb } from 'react-bootstrap';
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom';
import "../scss/Breadcrumbs.scss";

type Props = {}

const BreadcrumbMenu = (props: Props) => {
  const location = useLocation();
  const paths = location.pathname.split('/');
  let items = [];
  for (let i=1; i<paths.length; i++) {
    
    items.push(<Breadcrumb.Item key={`breadcrumb-${i}`} linkAs={Link} linkProps={{to: `/${paths.slice(1, i+1).join('/')}`}} >{paths[i]}</Breadcrumb.Item>)
  }
  return (
    <Breadcrumb className="breadcrumbs">
      {items}
    </Breadcrumb>
  )
}

export default BreadcrumbMenu