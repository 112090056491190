import React from 'react'

type Props = {
  children: React.ReactNode;
}

const VerticalStudentName = (props: Props) => {
  return (
    <div className="VerticalStudentName">{props.children}</div>
  )
}

export default VerticalStudentName