import React, { Children } from "react";
import { Navigate, useLocation } from "react-router";
import BreadcrumbMenu from "../../sections/BreadcrumbMenu";
import useAuth from "./useAuth";

type Props = {
  levels?: string[];
  children: React.ReactNode;
};

const PrivateRoute = (props: Props) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth || !auth.user) {
    return <Navigate to="/Admin/Login" state={{ from: location }} replace />;
  }

  if (
    !props.levels ||
    props.levels.length === 0 ||
    props.levels.includes(auth.user.level)
  )
    return (
      <>
        <BreadcrumbMenu />
        {props.children}
      </>
    );
  else return <>Unauthorized</>;
};

export default PrivateRoute;
