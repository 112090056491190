import { setDefaultResultOrder } from "dns";
import { useState } from "react";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";

type Props = {
  name: string;
  school?: number;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onSave?: (school: number) => boolean | Promise<boolean>;
  grade?: number;
};

interface FormikValues {
  Student: {
    IncomingGrade: number;
  };
}

const SchoolSelector = (props: Props) => {
  const [school, setSchool] = useState<number>(props.school || 0);
  const [initialSchool, setInitialSchool] = useState<number>(props.school || 0);
  const [saved, setSaved] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  // React.useEffect(() => {
  //   setSchool(props.school);
  //   setInitialSchool(props.school);
  // }, [props.school])

  const onChange = (e: any) => {
    console.log("School", e.target.value);
    setSchool(e.target.value);
    if (e.target.value !== initialSchool) {
      setSaved(false);
    } else {
      setSaved(true);
    }
  };

  const onSave = async () => {
    if (props.onSave) {
      if (await props.onSave(school)) {
        setSaved(true);
        setError("");
      } else {
        setError("There was a problem saving the assigned school.");
        setSaved(false);
      }
    } else {
      setSaved(true);
      setError("");
    }
  };

  return (
    <>
    <InputGroup>
      {props.label && (
        <Form.Label>
          {props.label}
          {props.required && <span className="label-required">*</span>}
        </Form.Label>
      )}
      <Form.Select value={school} disabled={props.disabled} onChange={onChange} className="form-select-noformik">
        <option value="0">Select a School</option>
        {(!props.grade || props.grade < 7) && (
          <option value="5">Cambridge Elementary</option>
        )}
        {(!props.grade || props.grade < 7) && (
          <option value="1">Center Elementary</option>
        )}
        {(!props.grade || props.grade < 7) && (
          <option value="6">Foxboro Elementary</option>
        )}
        {(!props.grade || props.grade < 7) && (
          <option value="3">Scandia Elementary</option>
        )}
        {(!props.grade || props.grade < 7) && (
          <option value="2">Travis Elementary</option>
        )}
        {(!props.grade || props.grade > 6) &&
          (!props.grade || props.grade < 9) && (
            <option value="4">Golden West Middle</option>
          )}
        {(!props.grade || props.grade > 8) && (
          <option value="21">Vanden High</option>
        )}
        {(!props.grade || props.grade > 8) && (
          <option value="23">TEC (Continuation)</option>
        )}
      </Form.Select>
      {!saved && (
        <>
          <Button variant="success" onClick={onSave}>
            <i className="fa fa-floppy-o" />
          </Button>
          <Button
            variant="warning"
            onClick={() => {
              setSchool(initialSchool);
              setSaved(true);
              setError("");
            }}
          >
            <i className="fa fa-undo" />
          </Button>
          {error && (
            <Alert variant="danger">{error}</Alert>
            )}
        </>
      )}
      </InputGroup>
    </>
  );
};

export default SchoolSelector;
