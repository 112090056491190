import { useFormikContext } from "formik";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { pdfjs, Document, Page } from "react-pdf";
import { InterdistrictApplication } from "tusd-common";
import ApplicationDocument from "./ApplicationDocument";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

type Props = {
  onReady: VoidFunction;
};

export const fileType = (fileLink: string) => {
  return fileLink.toLowerCase().substring(fileLink.lastIndexOf(".") + 1);
};

const ApplicationDocuments = (props: Props) => {
  const formik = useFormikContext<InterdistrictApplication>();
  const [documentReady, setDocumentReady] = React.useState<Array<boolean>>([]);
  const [readyIndex, setReadyIndex] = React.useState<number>(0);

  React.useEffect(() => {
    let canPrint = true;
    for (let i = 0; i < documentReady.length; i++) {
      if (!documentReady[i]) canPrint = false;
    }
    console.log("Can we print?", canPrint, documentReady);
    if (canPrint) {
      props.onReady();
    }
  }, [readyIndex]);

  let Documents: any = [];

  // Release Out
  if (
    formik?.values?.Student?.ReleaseDoc &&
    Array.isArray(formik?.values?.Student?.ReleaseDoc) &&
    formik?.values?.Student?.ReleaseDoc.length > 0
  ) {
    Documents = [...Documents, ...formik?.values?.Student?.ReleaseDoc];
  }

  // Transcript / Grade Report
  if (
    formik?.values?.Student?.TranscriptsDoc &&
    Array.isArray(formik?.values?.Student?.TranscriptsDoc) &&
    formik?.values?.Student?.TranscriptsDoc.length > 0
  ) {
    Documents = [...Documents, ...formik?.values?.Student?.TranscriptsDoc];
  }

  if (
    formik?.values?.Student?.GuardianActiveDutyMilitaryDoc &&
    Array.isArray(formik?.values?.Student?.GuardianActiveDutyMilitaryDoc) &&
    formik?.values?.Student?.GuardianActiveDutyMilitaryDoc.length > 0
  ) {
    Documents = [
      ...Documents,
      ...formik?.values?.Student?.GuardianActiveDutyMilitaryDoc,
    ];
  }

  if (
    formik?.values?.Student?.GuardianTUSDEmployeeDoc &&
    Array.isArray(formik?.values?.Student?.GuardianTUSDEmployeeDoc) &&
    formik?.values?.Student?.GuardianTUSDEmployeeDoc.length > 0
  ) {
    Documents = [
      ...Documents,
      ...formik?.values?.Student?.GuardianTUSDEmployeeDoc,
    ];
  }

  if (
    formik?.values?.AdditionalQuestions?.SpedIEP?.Doc &&
    Array.isArray(formik?.values?.AdditionalQuestions?.SpedIEP?.Doc) &&
    formik?.values?.AdditionalQuestions?.SpedIEP?.Doc.length > 0
  ) {
    Documents = [
      ...Documents,
      ...formik?.values?.AdditionalQuestions?.SpedIEP?.Doc,
    ];
  }

  let tDocReady: Array<boolean> = [];
  for (let i = 0; i < Documents.length; i++) {
    tDocReady.push(false);
  }
  if (documentReady.length !== tDocReady.length) setDocumentReady(tDocReady);

  return Documents.map((e: any, i: any) => {
    let f = fileType(e.fileLink);
    let doc = documentReady;
    if (
      f === "jpg" ||
      f === "jpeg" ||
      f === "png" ||
      f === "docx" ||
      f === "heic"
    ) {
      doc[i] = true;
    }
    if (doc !== documentReady) {
      setDocumentReady(doc);
      setReadyIndex(readyIndex + 1);
    }

    return (
      <Row key={`document-${i}`} className="singlepage">
        <ApplicationDocument
          file={e}
          onReady={() => {
            let tDoc = documentReady;
            tDoc[i] = true;
            setDocumentReady(tDoc);
            setReadyIndex(readyIndex + 1);
          }}
        />
      </Row>
    );
  });
};

export default ApplicationDocuments;
