import { setNestedObjectValues, useFormikContext } from "formik";
import React from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
} from "react-bootstrap";
import { ApplicationStatus, InterdistrictApplication } from "tusd-common";
import {
  formatBirthdate,
  formatDate,
  formatGrade,
  formatPhone,
  printAcademicYear,
  schoolName,
} from "util/util";
import "scss/InterdistrictAdminReview.scss";
import axios from "axios";
import Loading from "components/Loading";
import StatusSelector from "components/StatusSelector";
import ApplicationStatusSelector from "components/forms/ApplicationStatusSelector";
import ApplicationNotes from "./ApplicationNotes";
import OfficeUseVerifications, {
  Verifications,
} from "./OfficeUseVerifications";
import SchoolSelector from "components/forms/SchoolSelector";
import SchoolSelectorNoFormik from "components/forms/SchoolSelectorNoFormik";
import useApi from "hooks/useApi";
import TextareaNoFormik from "components/forms/TextareaNoformik";
import ApplicationDocuments, { fileType } from "./ApplicationDocuments";
import FileField from "components/forms/FileField";
import UploadApplicationDocument from "components/forms/UploadApplicationDocument";
import ConfirmButton from "components/ConfirmButton";
import VerticalStudentName from "./VerticalStudentName";
import SelectSave from "components/forms/SelectSave";

type Props = {
  applicationid?: number;
  canPrint?: boolean;
  canPrintMsg?: React.ReactNode;
};
/**
 * Data to include:
 * Student Last
 * Student First
 * Student Grade
 * Applicant Last
 * Applicant First
 * Applicant Phone
 * Applicant Email
 * Academic Year
 * Application Status
 * Checkboxes:
 *  - Correct Documents Uploaded
 *  - Approved by Student Services
 *  - Approved by School Site
 *  - Approved by SPED
 * Notes:
 *  - Table & Add New
 *
 */

interface Note {
  ApplicationID: number;
  NoteID: number;
  Username: string;
  Note: string;
  LastChangeDate: Date;
}

const InterdistrictAdminReview = (props: Props) => {
  const formik = useFormikContext<InterdistrictApplication>();
  const [status, setStatus] = React.useState<ApplicationStatus>(null!);
  const [docMessage, setDocMessage] = React.useState<Array<React.ReactNode>>(
    null!
  );
  const [documents, setDocuments] = React.useState<Array<React.ReactNode>>([]);
  // const [app, setApp] = React.useState<any>(null!);
  const appShort = useApi(
    `/api/admin/applications/app/${props.applicationid}/short`
  );
  const app = appShort?.data ? appShort.data[0] : undefined;

  const inter = useApi(
    `/api/admin/applications/app/interdistrict/${props.applicationid}`
  );

  const deleteDocument = (doc: any) => {
    if (!doc.fileLink) {
      return;
    }

    axios.delete(doc.fileLink).then((res) => {
      console.log(res);
    });
  };

  React.useEffect(() => {
    let Documents: any = [];

    // Release Out
    if (
      formik?.values?.Student?.ReleaseDoc &&
      Array.isArray(formik?.values?.Student?.ReleaseDoc) &&
      formik?.values?.Student?.ReleaseDoc.length > 0
    ) {
      Documents = [...Documents, ...formik?.values?.Student?.ReleaseDoc];
    }

    // Transcript / Grade Report
    if (
      formik?.values?.Student?.TranscriptsDoc &&
      Array.isArray(formik?.values?.Student?.TranscriptsDoc) &&
      formik?.values?.Student?.TranscriptsDoc.length > 0
    ) {
      Documents = [...Documents, ...formik?.values?.Student?.TranscriptsDoc];
    }

    if (
      formik?.values?.Student?.GuardianActiveDutyMilitaryDoc &&
      Array.isArray(formik?.values?.Student?.GuardianActiveDutyMilitaryDoc) &&
      formik?.values?.Student?.GuardianActiveDutyMilitaryDoc.length > 0
    ) {
      Documents = [
        ...Documents,
        ...formik?.values?.Student?.GuardianActiveDutyMilitaryDoc,
      ];
    }

    if (
      formik?.values?.Student?.GuardianTUSDEmployeeDoc &&
      Array.isArray(formik?.values?.Student?.GuardianTUSDEmployeeDoc) &&
      formik?.values?.Student?.GuardianTUSDEmployeeDoc.length > 0
    ) {
      Documents = [
        ...Documents,
        ...formik?.values?.Student?.GuardianTUSDEmployeeDoc,
      ];
    }

    if (
      formik?.values?.AdditionalQuestions?.SpedIEP?.Doc &&
      Array.isArray(formik?.values?.AdditionalQuestions?.SpedIEP?.Doc) &&
      formik?.values?.AdditionalQuestions?.SpedIEP?.Doc.length > 0
    ) {
      Documents = [
        ...Documents,
        ...formik?.values?.AdditionalQuestions?.SpedIEP?.Doc,
      ];
    }
    let docMessage: Array<React.ReactNode> = [];
    let docs: Array<React.ReactNode> = [];
    for (let i = 0; i < Documents.length; i++) {
      docs.push(
        <ListGroup.Item>
          <b>{Documents[i].field}: </b>
          <a href={Documents[i].fileLink} target="new">
            {Documents[i].name}
          </a>
          <ConfirmButton
            noButton
            onClick={() => deleteDocument(Documents[i])}
            confirmTitle="Are you sure?"
            confirmText={`Are you sure you want to delete the ${Documents[i].field} document ${Documents[i].name}?`}
          >
            <a className="deleteLink" href="#delete">
              <i className="fa fa-trash" />
            </a>
          </ConfirmButton>
        </ListGroup.Item>
      );
      if (["docx", "heic"].includes(fileType(Documents[i].name))) {
        docMessage.push(
          <p>
            Unable to display {Documents[i].field} document. Please{" "}
            <a href={Documents[i].fileLink} target="new">
              download <b>{Documents[i].name}</b>
            </a>{" "}
            and print it directly.
          </p>
        );
      }
    }

    setDocuments(docs);
    setDocMessage(docMessage);
  }, []);

  // React.useEffect(() => {
  //   axios
  //     .get(`/api/admin/applications/app/${props.applicationid}/short`)
  //     .then((res) => {
  //       if (res.data.data) {
  //         setStatus(res.data.data[0].ApplicationStatus);
  //         setApp(res.data.data[0]);
  //       }
  //     });
  // }, []);

  const saveAssignedSchool = async (school: number) => {
    let res = await axios
      .put(
        `/api/admin/applications/app/interdistrict/${props.applicationid}/officeuse`,
        { ApplicationID: app.ApplicationID, OfficeUseAssignedSchool: school }
      )
      .catch((err) => {
        return err;
      });
    if (res?.data?.status === "Success") {
      return true;
    }

    return false;
  };

  if (inter.loading || !inter.data) return <Loading />;
  if (appShort.loading || !appShort?.data) return <Loading />;

  const ApplicantAddress = (() => {
    let guardians = formik.values.Guardians;
    for (let i=0; i<guardians.length; i++) {
      if (guardians[i].LivesWith) {
        return <span className="StreetAddress">{guardians[i].LastName}, {guardians[i].FirstName}<br />{guardians[i].Address}<br />{guardians[i].City}, {guardians[i].State} {guardians[i].Zip}</span>
      }
    }
    return ``;
  })();

  return (
    <div className="InterdistrictAdminReview">
      {/* <Button disabled={!props.canPrint} onClick={() => window.print()}>
        <i
          className={`fa ${props.canPrint ? "fa-print" : "fa-spinner fa-spin"}`}
        />{" "}
        Print
      </Button> */}
      {/* {docMessage && docMessage.length > 0 && (
        <Alert variant="secondary" className="hidden-print">
          {docMessage.map((e, i) => e)}
        </Alert>
      )} */}
      <VerticalStudentName>
        {formik?.values?.Student?.LastName},{" "}
        {formik?.values?.Student?.FirstName}
      </VerticalStudentName>
      <table className="InterCoverPage">
        <tbody>
          <tr>
            <th colSpan={10} className="center hr">
              Application for Interdistrict Attendance at Travis Unified School
              District
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Student</th>
            <th colSpan={1} className="center">
              Grade
            </th>
            <th colSpan={2}>Birthdate</th>
            <th colSpan={2}>Application Date</th>
            <th colSpan={1}>Year</th>
            <th colSpan={1}>New / Renewal</th>
            <th colSpan={1}>Status</th>
          </tr>
          <tr>
            <td colSpan={2}>
              {formik?.values?.Student?.LastName},{" "}
              {formik?.values?.Student?.FirstName}
            </td>
            <td colSpan={1} className="center">
              {formatGrade(formik?.values?.Student?.IncomingGrade)}
            </td>
            <td colSpan={2}>
              {formatBirthdate(formik?.values?.Student?.Birthdate)}
            </td>
            <td colSpan={2}>{formatDate(app?.ApplicationDate)}</td>
            <td colSpan={1}>
              <SelectSave
                value={app?.AcademicYear}
                onSave={(e) => {
                  if (e.newValue !== e.oldValue) {
                    axios
                      .put(`/api/applications/interdistrict`, {
                        ApplicationID: props.applicationid,
                        AcademicYear: e.newValue,
                      })
                      .then((res) => {
                        if (res.data.status === "Success") {
                          e.onSuccess();
                          window.location.reload();
                        } else {
                          e.onFailure(res.data.message);
                        }
                      });
                  }
                }}
              >
                {(() => {
                  let now = new Date();
                  let thisYr = now.getFullYear();
                  let options = [];
                  for (let i = thisYr - 2; i <= thisYr + 2; i++) {
                    options.push(
                      <option value={i}>{printAcademicYear(i)}</option>
                    );
                  }
                  return options;
                })()}
              </SelectSave>
              <span className="onlyprint inline">
                {printAcademicYear(app?.AcademicYear)}
              </span>
            </td>
            <td colSpan={1}>
              <SelectSave
                value={formik?.values?.Student?.NewStudent ? "new" : "renewal"}
                onSave={(e) => {
                  if (e.newValue !== e.oldValue) {
                    axios
                      .put(`/api/applications/interdistrict`, {
                        ApplicationID: props.applicationid,
                        ReasonNewStudent: e.newValue === "new" ? true : false,
                      })
                      .then((res) => {
                        if (res.data.status === "Success") {
                          e.onSuccess();
                          window.location.reload();
                        } else {
                          e.onFailure(res.data.message);
                        }
                      });
                  }
                }}
              >
                <option value="new">New Student</option>
                <option value="renewal">Renewal </option>
              </SelectSave>
              <span className="onlyprint inline">
                {formik?.values?.Student?.NewStudent
                  ? "New Student"
                  : "Renewal"}
              </span>
            </td>
            <td colSpan={1}>
              {app.ApplicationStatus ? (
                <span className="onlyprint inline">
                  {app.ApplicationStatus}
                </span>
              ) : (
                ""
              )}{" "}
              {app.ApplicationStatus ? (
                <ApplicationStatusSelector
                  status={app.ApplicationStatus}
                  applicationid={props.applicationid}
                  onSave={() => appShort.refresh()}
                />
              ) : (
                <Loading />
              )}
              {app?.ApplicationStatusDate && (
                <>
                  <br />
                  <span className="small">
                    {formatDate(app.ApplicationStatusDate)}
                  </span>
                </>
              )}
            </td>
          </tr>
          <tr>
            <th>Applicant:</th>
            <td colSpan={4} style={{borderRight: 0}}><span>{app?.ApplicantLastName}, {app?.ApplicantFirstName}<br />{formatPhone(app?.PrimaryPhoneNumber)}<br /><a href={`mailto:${app?.PrimaryEmailAddress}`}>{app?.PrimaryEmailAddress}</a></span></td>
            <td colSpan={5} style={{borderLeft: 0}}><span>{ApplicantAddress}</span></td>
          </tr>
        </tbody>
      </table>
      {/* Requested Schools */}
      <table className="InterCoverPage noTopBorder">
        <tbody>
          <tr>
            <th rowSpan={2} style={{ width: "10%" }}>
              School
            </th>
            <th style={{ width: "22.5%" }}>First Choice</th>
            <th style={{ width: "22.5%" }}>Second Choice</th>
            <th style={{ width: "22.5%" }}>Third Choice</th>
            <th style={{ width: "22.5%" }}>Assigned</th>
          </tr>
          <tr>
            <td>
              {formik?.values?.Student?.NewStudent
                ? schoolName(formik?.values?.Student?.FirstChoiceSchool)
                : "Not Applicable"}
            </td>
            <td>
              {formik?.values?.Student?.NewStudent
                ? schoolName(formik.values.Student.SecondChoiceSchool)
                : "Not Applicable"}
            </td>
            <td>
              {formik?.values?.Student?.NewStudent
                ? schoolName(formik.values.Student.ThirdChoiceSchool)
                : "Not Applicable"}
            </td>
            <td>
              <SchoolSelectorNoFormik
                name="AssignedSchool"
                label=""
                school={inter?.data[0]?.OfficeUseAssignedSchool}
                onSave={saveAssignedSchool}
                grade={formik?.values?.Student?.IncomingGrade}
              />
              <span className="onlyprint">{schoolName(0)}</span>
            </td>
          </tr>
          <tr>
            <th>Reason for Request</th>
            <td colSpan={4}>
              <Form.Check
                className="fullopac"
                label="Parent/Legal Guardian of the student is Active Duty Military"
                disabled
                checked={formik?.values?.Student?.GuardianActiveDutyMilitary}
              />
              <Form.Check
                className="fullopac"
                label="Parent/Legal Guardian of the student is an employee of Travis USD"
                disabled
                checked={formik?.values?.Student?.GuardianTUSDEmployee}
              />
              <Form.Check
                className="fullopac"
                label="Sibling of a student currently attending a Travis USD school."
                disabled
                checked={formik?.values?.Student?.SiblingAttendsTUSD}
              />
              <Form.Check
                className="fullopac"
                label={
                  <span>
                    <b
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Mid-Year
                    </b>{" "}
                    Recently moved out of Travis USD attendance boundaries and
                    would like to complete the trimester (elementary, semester
                    (secondary) or school year.
                  </span>
                }
                disabled
                checked={formik?.values?.Student?.MidYearMove}
              />
              <Form.Check
                className="fullopac"
                label="Other"
                disabled
                checked={formik?.values?.Student?.Other}
              />
              {formik?.values?.Student?.Other && (
                <Alert variant="secondary">
                  {formik?.values?.Student?.OtherText}
                </Alert>
                // <TextareaNoFormik
                //   disabled
                //   value={formik?.values?.Student?.OtherText}
                // />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="InterCoverPage noTopBorder">
        <tbody>
          <tr>
            <th className="center hr">Verifications</th>
          </tr>
        </tbody>
      </table>
      <table className="InterCoverPage noTopBorder">
        <tbody>
          <OfficeUseVerifications
            applicationid={props.applicationid}
            data={inter.data}
            loading={inter.loading}
            refresh={inter.refresh}
          />
        </tbody>
      </table>
      <table className="InterCoverPage hidden-print noTopBorder">
        <tbody>
          <tr>
            <th>Documents</th>
            <td>
              <ListGroup>
                {documents}
                <ListGroupItem>
                  <UploadApplicationDocument
                    applicationid={props.applicationid}
                  />
                </ListGroupItem>
              </ListGroup>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="InterCoverPage noTopBorder">
        <tbody>
          <tr>
            <th className="center hr">Notes</th>
          </tr>
        </tbody>
      </table>
      <ApplicationNotes
        className="InterCoverPage noTopBorder"
        applicationid={props.applicationid}
      />
      <hr />
    </div>
  );
};

export default InterdistrictAdminReview;
