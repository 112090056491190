import FileField from "../../../components/forms/FileField";
import React from "react";
import { Card, Alert } from "react-bootstrap";
import Field from "../../../components/forms/Field";
import OffCanvasLink from "../../../components/OffCanvasLink";
import InitialField from "components/forms/InitialField";

type Props = {
  isAdmin?: boolean;
  disabled?: boolean;
};

const InterdistrictRequestInfo = (props: Props) => {
  const disabled = props.disabled || false;
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Inter District Attendance (IDA) Request Information
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <p>
          Read this section thoroughly. You must also enter your initials in
          several places to confirm that you have read.
        </p>
        <hr />
        <p>
          <strong>
            <u>Request Process:</u>
          </strong>{" "}
          In order to process your request, the following steps must be
          completed:
        </p>
        <ol>
          <li>
            <u>IDA Release:</u> Obtain a release from your resident school
            district by contacting the Student Services offices in the district
            where you live.
            <br />
            <FileField
              label="Please upload your IDA Release Documents here."
              name="Student.ReleaseDoc"
              disabled={disabled}
            />
          </li>
          <li>
            <u>Complete IDA Request:</u> Complete the below Inter District
            Attendance Agreement Request.
            <ol>
              <li>
                Please review{" "}
                <a
                  href="https://simbli.eboardsolutions.com/Policy/ViewPolicy.aspx?S=36030187&revid=PACslsh8StRPHnXgbbDD1LKkw==&ptid=amIgTZiB9plushNjl6WXhfiOQ==&secid=9slshUHzTHxaaYMVf6zKpJz3Q==&PG=6&IRP=0&isPndg=false"
                  target="new"
                >
                  AR 5117
                </a>{" "}
                to view the Governing Board's established priorities.
                <br />
                <InitialField
                  name="Applicant.ConfirmAR5117"
                  disabled={disabled}
                  label={
                    <span>
                      <i
                        style={{ color: "red" }}
                        className="fa fa-exclamation-triangle"
                      />{" "}
                      I have reviewed and understand the Governing Board's
                      established priorities outlined in{" "}
                      <a
                        href="https://simbli.eboardsolutions.com/Policy/ViewPolicy.aspx?S=36030187&revid=PACslsh8StRPHnXgbbDD1LKkw==&ptid=amIgTZiB9plushNjl6WXhfiOQ==&secid=9slshUHzTHxaaYMVf6zKpJz3Q==&PG=6&IRP=0&isPndg=false"
                        target="new"
                      >
                        AR 5117
                      </a>
                      .
                    </span>
                  }
                />
              </li>
            </ol>
          </li>
          <li>
            <u>Report Card or Transcript:</u> Attach a copy of the most recent
            report card (TK-8) or transcript (9-12) unless the student is
            currently enrolled in a Travis USD school.
          </li>
          <li>
            <u>Submit</u> the below request form electronically.
          </li>
          <li>
            <u>Maintain Enrollment</u> in your child's current school until you
            receive confirmation that your application has been approved. Due to
            the number of requests we receive, your application may not be
            approved until after the start of the school year.
          </li>
        </ol>
        <InitialField
          name="Applicant.ConfirmRequestProcess"
          disabled={disabled}
          label={
            <span>
              <i
                style={{ color: "red" }}
                className="fa fa-exclamation-triangle"
              />{" "}
              I have reviewed and understand the Request Process outlined above.
              I understand that{" "}
              <strong>
                my child should remain enrolled in and continue to attend their
                resident school until otherwise notified by Travis Unified
                School District.
              </strong>
            </span>
          }
        />
        <p>
          <strong>
            <u>Timeline:</u>
          </strong>
        </p>
        <p>
          Upon receipt of your complete request form, a lottery will take place
          and you will be notified of your student's status. IDA request will be
          accepted as follows:
        </p>
        <ul>
          <li>
            New IDA Request submitted{" "}
            <u>
              between the second Monday in February and the second Friday in
              March
            </u>
            <ol>
              <li>
                All requests submitted during this time period will be placed in
                a lottery and assigned an order based on grade and school.
              </li>
              <li>
                Students will be approved based on space availability at each
                school site and grade level.
              </li>
              <li>
                Those not approved will be placed on a wait list in order as
                established in the lottery.
              </li>
              <li>
                Current students, children of Active Duty Military, and children
                of employees of the Travis Unified School District will receive
                priority based on space availability.
              </li>
            </ol>
          </li>
          <li>
            New IDA Requests submitted <u>after the second Friday in March</u>
            <ul>
              <li>
                All requests submitted will be placed on a wait list in the
                order submitted.
              </li>
              <li>
                Current students, children of Active Duty Military, and children
                of employees of the Travis Unified School District will receive
                priority based on space availability.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>
            <u>Note:</u>
          </strong>{" "}
          We cannot guarantee that you will be notified by the first day of
          school. Please have your student register and attend their school of
          residence until otherwise notified by Travis Unified School District.
        </p>
        <InitialField
          label={
            <span>
              <i
                style={{ color: "red" }}
                className="fa fa-exclamation-triangle"
              />{" "}
              I have reviewed and understand the Timeline outlined above. I
              understand that this IDA Request may not be approved until after
              the start of the school year and{" "}
              <strong>
                I will need to enroll my child in their resident school.
              </strong>
            </span>
          }
          name="Applicant.ConfirmRequestTimeline"
          disabled={disabled}
        />
        <p>
          <strong>
            <u>Conditions:</u>
          </strong>
        </p>
        <ul>
          <li>
            <u>New Students:</u> The district may deny Inter District requests
            due to limited district resources, overcrowding of school facilities
            at the relevant grade level or other considerations that are not
            arbitrary.
          </li>
          <li>
            <u>Continuing Students:</u> Annual Review: The Agreement for Inter
            District Attendnace will be reviewed annually for compliance to
            determine if IDA will be renewed for the upcoming school year.
          </li>
          <li>
            <u>Continuing Students:</u> IDA shall not exceed a term of 6 years.
          </li>
          <li>
            <u>Continuing Students:</u> IDA expires if the student's grade
            exceeds the highest grade at their assigned school.
            <br />
            <i>
              For example, if a student is approved for an IDA in 6th grade and
              attends Travis Elementary, a new IDA Application must be submitted
              and approved prior to the student entering 7th grade and enrolling
              at Golden West Middle School.
            </i>
          </li>
        </ul>
        <p>
          If approved, each student attending is expected to comply with the IDA
          Agreement and will be reviewed annually for compliance. Students who
          comply with the IDA agreement may receive an automatic renewal not to
          exceed six years. Non-compliance will result in the IDA being
          non-renewed.
        </p>
        <InitialField
          label={
            <span>
              <i
                style={{ color: "red" }}
                className="fa fa-exclamation-triangle"
              />{" "}
              I have reviewed and understand the conditions of this IDA
              Agreement outlined above.
            </span>
          }
          name="Applicant.ConfirmRequestConditions"
          disabled={disabled}
        />
        <p>
          If you have any questions about the IDA process, please feel free to
          contact the Student Services office at (707) 437-4604 x1114 for
          assistance or email syoung@travisusd.org
        </p>
      </Card.Body>
    </Card>
  );
};

export default InterdistrictRequestInfo;
