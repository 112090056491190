import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

const ScrollToError = () => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;
  const [show, setShow] = useState<boolean>(false);
  const [dismissed, setDismissed] = useState<boolean>(false);
  
  useEffect(() => {
    const el = document.querySelector(".is-invalid");
    if (el) {
      setShow(true);
    }
    return () => setShow(false);
    
  }, [submitting]);

  useEffect(() => {
    if (dismissed) {
      const el = document.querySelector(".is-invalid");
      (el?.parentElement ?? el)?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
    setDismissed(false);
  }, [dismissed])

  if (!show) return null; 
  
  return (
    <Modal show={show} onHide={() => {setShow(false); setDismissed(true)}}>
      <Modal.Header closeButton>
        <Modal.Title>There are errors in your application.</Modal.Title>
      </Modal.Header>
      <Modal.Body>Please review the highlighted fields and try again.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {setShow(false); setDismissed(true)}}>Ok</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ScrollToError