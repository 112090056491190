import React, { useEffect, useState } from "react";

interface Filters {
  [key: string]: string[] | string;
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
  NONE = "NONE",
}

export interface DataFilter {
  sortKey: string;
  sortOrder: SortOrder;
  filtered: Filters;
  searched: Filters;
  page: number;
  numPages?: number;
  numPerPage: number;
  last?: DataRow;
  first?: DataRow;
}

export interface DataControls extends DataFilter {
  onSearch: (field: string) => (value: string) => void;
  onSort: (field: string) => (order: SortOrder) =>  void;
  onFilter: (field: string) => (value: string) => void;
  onPage: (page: number) => void;
  getFilter: (field: string) => string[] | string | undefined;
  getSearch: (field: string) => string[] | string | undefined;
  getSort: () => Sort;
}

export interface DataControllerProps {
  data: DataRow[];
  totalItems?: number;
  initial?: DataFilter;
  paged: boolean;
  children: React.ReactNode;
  defaultSort?: {sortKey: string, sortOrder: SortOrder};
  onControl?: (controls: DataFilter) => void;
}

interface DataRow {
  [index: string]: any;
}

export interface Sort {
  field: string;
  order: SortOrder;
}

const DataController = ({
  data = [],
  totalItems = undefined,
  initial = undefined,
  paged = true,
  defaultSort,
  onControl = (controls: DataFilter) => { /* */ },
  children,
  ...props
}: DataControllerProps) => {
  const [sortKey, setSortKey] = useState<string>(() => {
    if (initial?.sortKey)
      return initial.sortKey;
    if (defaultSort?.sortKey)
      return defaultSort.sortKey;
    return ""
  });
  
  const [sortOrder, setSortOrder] = useState<SortOrder>(() => {
    if (initial?.sortOrder)
      return initial.sortOrder;
    if (defaultSort?.sortOrder)
      return defaultSort.sortOrder;
    return SortOrder.NONE;}
  );

  const [filtered, setFiltered] = useState<Filters>(initial?.filtered ?? {});
  const [searched, setSearched] = useState<Filters>(initial?.searched ?? {});

  const [page, setPage] = useState<number>(() => {
    if (initial?.page) {
      let page = initial?.page;
      if (typeof page === "string") {
        page = parseInt(page);
        if (isNaN(page)) return 0;
      }
      return page;
    } else {
      return 0;
    }
  });
  const [numPages, setNumPages] = useState<number>(initial?.numPages ?? 1);
  const [numPerPage, setNumPerPage] = useState<number>(
    initial?.numPerPage ?? 10
  );

  
  const [numTotalItems, setNumTotalItems] = useState<number>(
    totalItems || data.length
  );


  const [currentData, setCurrentData] = useState<Array<DataRow>>([]);

  const [pendingUpdate, setPendingUpdate] = useState<boolean>(false);

  /* getFilter(field)
   * Gets the filter value(s) for the specified field.
   */
  const getFilter = (field: string): string[] | string | undefined => {
    return filtered[field] || [];
  };

  /* getSearch(field)
   * Gets the searched value(s) for the specified field.
   */
  const getSearch = (field: string): string[] | string | undefined => {
    return searched[field] || [];
  };

  /* getSort()
   * Gets the current sort field and order.
   */
  const getSort = (): Sort => {
    return {
      field: sortKey,
      order: sortOrder,
    };
  };

  /* onSort(field)
   * Returns a function to change the sort key and sort order for field.
   * Also flags the pendingUpdate var.
   */
  const onSort = (field: string): Function => {
    return (order: SortOrder): void => {
      setPage(0);
      setSortKey(field);
      setSortOrder(order);
      onChange();
    };
  };

  /* onFilter(field)
   * Returns a function to change the filters for the specified field.
   * Also flags the pendingUpdate var.
   */
  const onFilter = (field: string, search: boolean = false): Function => {
    return (filter: string): void => {
      let f = search ? { ...searched } : { ...filtered };
      let current = f[field];
      // If we have an array of values...
      if (Array.isArray(current)) {
        let i: number;
        if ((i = current.indexOf(filter)) >= 0) {
          current.splice(i, 1);
        } else {
          current.push(filter);
        }
      } else {
        if (current === filter) current = "";
        else {
          if (search) current = filter;
          else {
            if (current) current = [current, filter];
            else current = filter;
          }
        }
      }
      f[field] = current;
      if (search) setSearched(f);
      else setFiltered(f);
      setPage(0);
      onChange();
    };
  };

  /* onSearch(field)
   * Returns a function to change the searchs for the specified field.
   * Also flags the pendingUpdate var.
   * This function is the exact same process as the onFilter so we will re-use that function.
   */
  const onSearch = (field: string): Function => {
    return onFilter(field, true);
  };

  const onPage = (page: number) => {
    setPage(page);
    onChange();
  };

  const onNumPerPage = (num: number) => {
    setNumPerPage(num);
    setPage(0);
    onChange();
  };

  /* clearFilter(field)
   * Clears the filters for specified field. If field is not provided, it clears all filters.
   * Also flags the pendingUpdate var.
   */
  const clearFilter = (field: string | undefined) => {
    let filters = { ...filtered };
    if (field) filters[field] = [];
    else {
      filters = {};
    }
    setFiltered(filters);
    onChange();
  };

  /* clearSearch(field)
   * Clears the searches for specified field. If field is not provided, it clears all searches.
   * Also flags the pendingUpdate var.
   */
  const clearSearch = (field: string | undefined) => {
    let searches = { ...searched };
    if (field) searches[field] = [];
    else searches = {};
    setSearched(searches);
    onChange();
  };

  const updateCurrentData = (): void => {
    let cData = [...data];
    // console.log("Current cData = ", cData);

    // Apply filters first.
    for (let f in filtered) {
      cData = cData.filter((row: DataRow) => {
        if (!Array.isArray(filtered[f])) {
          if (filtered[f] !== "") return filtered[f] === row[f];
          else return true;
        } else {
          if (filtered[f].length > 0) return filtered[f].indexOf(row[f]) >= 0;
          else return true;
        }
      });
    }

    // Apply searches
    for (let s in searched) {
      cData = cData.filter((row: DataRow) => {
        if (!Array.isArray(searched[s])) {
          if (searched[s][0] !== "-") {
            return row[s]
              .toString()
              .toLowerCase()
              .includes(searched[s].toString().toLowerCase());
          } else {
            return !row[s]
              .toString()
              .toLowerCase()
              .includes(searched[s].toString().substring(1).toLowerCase());
          }
        } else {
          for (let v = 0; v < searched[s].length; v++) {
            if (searched[s][v][0] !== "-") {
              if (
                row[s]
                  .toString()
                  .toLowerCase()
                  .includes(searched[s][v].toLowerCase())
              )
                return true;
            } else {
              if (
                row[s]
                  .toString()
                  .toLowerCase()
                  .includes(searched[s][v].substring(1).toLowerCase())
              )
                return false;
            }
          }
          return false;
        }
      });
    }

    // Apply Sort
    if (sortKey && sortKey !== "") {
      cData = cData.sort((a, b) => {
        return sortOrder === "ASC"
          ? a[sortKey] > b[sortKey]
            ? 1
            : a[sortKey] < b[sortKey]
            ? -1
            : 0
          : a[sortKey] < b[sortKey]
          ? 1
          : a[sortKey] > b[sortKey]
          ? -1
          : 0;
      });
    }

    // Apply Paging
    if (paged) {
      cData = cData.slice(page * numPerPage, (page + 1) * numPerPage);
    }
    // console.log("Setting current data: ", cData);
    // if (cData.length > 0) {
    //   setFirst(cData[0]);
    //   setLast(cData[cData.length - 1]);
    // }
    setCurrentData(cData);
    console.log("Current Data: ", cData);
  };

  const onChange = () => {
    setPendingUpdate(true);
  };

  useEffect(() => {
    console.log('OnControl');
    onControl({
      sortKey,
      sortOrder,
      filtered,
      searched,
      page,
      numPerPage,
      last: currentData.length > 0 ? currentData[currentData.length-1] : undefined,
      first: currentData.length > 0 ? currentData[0] : undefined,
    });
  }, [sortKey, sortOrder, filtered, searched, page, numPerPage]);

  useEffect(() => {
    if (totalItems) setNumPages(totalItems / numPerPage);
    else setNumPages(data.length / numPerPage);
  }, [data, totalItems, numPerPage]);

  useEffect(() => {
    console.log('Pending update...', data);
    setPendingUpdate(true);
  }, [data, page, numPerPage]);

  useEffect(() => {
    if (pendingUpdate) {
      updateCurrentData();
      setPendingUpdate(false);
    }
  }, [pendingUpdate]);

  const childProps = {
    data: currentData,
    sortKey,
    sortOrder,
    filtered,
    searched,
    controls: {
      sortKey,
      sortOrder,
      page,
      numPages,
      getFilter,
      getSearch,
      getSort,
      onSort,
      onSearch,
      onFilter,
      onPage,
      onNumPerPage,
      clearFilter,
      clearSearch,
    },
  };

  return  (<>
  {React.Children.map(children, (child) => React.isValidElement(child) ? React.cloneElement(child, {...childProps}) : child)}
  </>);
};

export default DataController;
