import { FieldArray } from "formik";
import React from "react";
import { Alert, Card, Row, Col, Button } from "react-bootstrap";
import {
  InterdistrictApplication,
  IDAApplicationEmpty as emptySchema,
} from "tusd-common";
import { useFormikContext } from "formik";
import SelectField from "components/forms/SelectField";
import Field from "components/forms/Field";
import StateSelector from "components/forms/StateSelector";
import CheckField from "components/forms/CheckField";

type Props = {
  disabled?: boolean;
};

const InterdistrictGuardianInfo = (props: Props) => {
  const formik = useFormikContext<InterdistrictApplication>();
  const disabled = props.disabled || false;
  return (
    <Card>
      <Card.Header>
        <Card.Title>Parent/Guardian Information</Card.Title>
      </Card.Header>
      <Card.Body>
        <p>
          Please provide information about the parent(s)/guardian(s) for this
          student.
        </p>
        <hr />
          {(!formik.values.Guardians || formik.values.Guardians.length < 1) && (
            <Alert variant="danger">You must add at least 1 Guardian</Alert>
          )}
        <FieldArray
          name="Guardians"
          render={(arrayHelpers) => (
            <>
              {/* {console.log(formik)} */}
              {formik.values.Guardians &&
                formik.values.Guardians.length > 0 &&
                formik.values.Guardians.map((g, i) => (
                  <Row key={i}>
                    {!disabled && (
                      <Card.Title>
                        Parent/Guardian {i + 1}{" "}
                        <Button
                          variant="danger"
                          onClick={arrayHelpers.handleRemove(i)}
                        >
                          Remove&nbsp;<i className="fa fa-times"></i>
                        </Button>
                      </Card.Title>
                    )}
                    <Row>
                      <Col sm={4}>
                        <SelectField
                          required
                          label="Relation"
                          placeholder="Relation..."
                          name={`Guardians.${i}.Relation`}
                          options={["Mother", "Father", "Guardian"]}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={4}>
                        <Field
                          required
                          label="Last Name"
                          name={`Guardians.${i}.LastName`}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={4}>
                        <Field
                          required
                          label="First Name"
                          name={`Guardians.${i}.FirstName`}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={5}>
                        <Field
                          required
                          label="Address"
                          name={`Guardians.${i}.Address`}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={3}>
                        <Field
                          required
                          label="City"
                          name={`Guardians.${i}.City`}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={2}>
                        <StateSelector
                          required
                          label="State"
                          name={`Guardians.${i}.State`}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={2}>
                        <Field
                          required
                          label="Zip"
                          name={`Guardians.${i}.Zip`}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: "10px" }}>
                      <Col sm={4}>
                        <Field
                          required
                          label="Home/Primary Phone"
                          name={`Guardians.${i}.HomePhone`}
                          disabled={disabled}
                        />
                      </Col>
                      <Col sm={4}>
                        <Field
                          label="Cell Phone"
                          name={`Guardians.${i}.CellPhone`}
                          disabled={disabled}
                          noValid
                        />
                      </Col>
                      <Col sm={4}>
                        <Field
                          label="Work Phone"
                          name={`Guardians.${i}.WorkPhone`}
                          disabled={disabled}
                          noValid
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Field
                          required
                          label="Email"
                          name={`Guardians.${i}.Email`}
                          disabled={disabled}
                          noValid
                        />
                      </Col>
                      <Col md={6}>
                        <CheckField
                          label="Check this if this guardian lives with the child."
                          name={`Guardians.${i}.LivesWith`}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                    <hr />
                  </Row>
                ))}
              <br />
              <Row>
                <Col xs={2}>
                  {!disabled &&
                    (!formik.values.Guardians ||
                      formik.values.Guardians.length < 2) && (
                      <Button
                        onClick={() => {
                          if (
                            !formik.values.Guardians ||
                            formik.values.Guardians.length < 1
                          ) {
                            let newGuardian = { ...emptySchema.Guardians[0] };
                            newGuardian.LastName =
                              formik.values.Applicant.LastName;
                            newGuardian.FirstName =
                              formik.values.Applicant.FirstName;
                            newGuardian.HomePhone = formik.values.Applicant.Phone;
                            newGuardian.Email = formik.values.Applicant.Email;
                            arrayHelpers.push(newGuardian);
                          } else {
                            arrayHelpers.push(emptySchema.Guardians[0]);
                          }
                        }}
                      >
                        Add Guardian
                      </Button>
                    )}
                </Col>
              </Row>
            </>
          )}
        ></FieldArray>
      </Card.Body>
    </Card>
  );
};

export default InterdistrictGuardianInfo;
