import Field from "components/forms/Field";
import FileField from "components/forms/FileField";
import SelectField from "components/forms/SelectField";
import Textarea from "components/forms/Textarea";
import { useField, useFormikContext } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { InterdistrictApplication } from "tusd-common";

type Props = {
  question: React.ReactNode;
  name: string;
  disabled?: boolean;
  required?: boolean;
  file?: React.ReactNode;
};

function JSONObjValueFromStr(json: any, str: string, sep = "."): any {
  let split = str.split(".");
  if (json === undefined) return undefined;
  if (split.length > 1) {
    return JSONObjValueFromStr(
      json[split[0]],
      split.splice(1, split.length - 1).join("."),
      sep
    );
  } else return json[split[0]];
}

const FormAdditionalQuestion = (props: Props) => {
  const formik = useFormikContext<InterdistrictApplication>();
  const [field, meta, helpers] = useField(`${props.name}.Response`);
  // console.log('response', formik?.values?.AdditionalQuestions?.PreviouslyAttendedTUSD?.Response)
  const isTrue =
    formik.values &&
    (JSONObjValueFromStr(formik.values, `${props.name}.Response`) === "true" ||
      JSONObjValueFromStr(formik.values, `${props.name}.Response`) === true);
  return (
    <>
      <Row>
        <Col>{props.question}{props.required && (<span className="label-required">*</span>)}</Col>
      </Row>
      <Row>
        <Col sm={3} md={2}>
          <SelectField
            label="Yes/No?"
            noValid
            name={`${props.name}.Response`}
            disabled={props.disabled}
            placeholder="---"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          />
        </Col>
        {isTrue && (
          <>
            <Col sm={4}>
              <Textarea
                label="If yes, when?"
                required
                noValid
                disabled={props.disabled}
                name={`${props.name}.When`}
              />
            </Col>

            <Col sm={4}>
              <Textarea
                label="If yes, where?"
                required
                noValid
                disabled={props.disabled}
                name={`${props.name}.Where`}
              />
            </Col>
          </>
        )}
      </Row>
      {isTrue && props.file && (
        <Row>
          <FileField
            required
            name={`${props.name}.Doc`}
            label={props.file}
            multiple={false}
            disabled={props.disabled}
          />
        </Row>
      )}
    </>
  );
};

export default FormAdditionalQuestion;
