import axios from "axios";
import SelectField from "components/forms/SelectField";
import React, { useEffect, useState } from "react";

type Props = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
};

const FormAcademicYearSelector = (props: Props) => {
  const [years, setYears] = useState<Array<number>>([]);

  useEffect(() => {
    axios.get("/api/Applications/Interdistrict/Settings").then((res: any) => {
      console.log("Academic years:", res);
      if (res?.data?.status === "Success") {
        let yrs = res?.data?.data?.ActiveAcademicYears;
        if (yrs) {
          yrs = yrs.split(",");
          for (let i = 0; i < yrs.length; i++) {
            yrs[i] = parseInt(yrs[i]);
          }
          setYears(yrs);
        }
      }
    });
  }, []);
  return (
    <SelectField
      name={props.name}
      label={props.label}
      placeholder="Choose Academic year"
      required={props.required}
      disabled={props.disabled}
    >
      {years.map((yr) => (
        <option key={yr} value={yr}>
          {yr - 1}-{yr}
        </option>
      ))}
    </SelectField>
  );
};

export default FormAcademicYearSelector;
