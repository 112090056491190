import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row } from "react-bootstrap";
import FormSection from "./components/forms/FormSection";
import { Field } from "formik";
import Header from "./sections/Header";
import Footer from "./sections/Footer";
import MainContent from "./sections/MainContent";
import "font-awesome/css/font-awesome.min.css";
import AuthProvider from "./hooks/auth/AuthProvider";

function App() {
  return (
    <Container fluid>
      <AuthProvider>
        <Header />
        <MainContent />
        <Footer />
      </AuthProvider>
    </Container>
  );
}

export default App;
