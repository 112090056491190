import { useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import Field from "./Field";
type Option = {
  value: string | number | boolean;
  label: string;
};

type Props = {
  label: React.ReactNode;
  name: string;
  disabled?: boolean;
  noValid?: boolean;
  noInvalid?: boolean;
  required?: boolean;
  options?: Array<Option | string>;
  children?: React.ReactNode;
  placeholder?: string;
};

const SelectField = (props: Props) => {
  const [field, meta] = useField({ name: props.name, type: "select" });
  const isValid = !props.noValid && meta.touched && !meta.error;
  const isInvalid =
    !props.noInvalid && meta.touched && meta.error !== undefined;
  return (
    <Form.Group>
      <Form.Label>
        {props.label}
        {props.required && <span className="label-required">*</span>}
      </Form.Label>
      <Form.Select
        {...field}
        isValid={isValid}
        isInvalid={isInvalid}
        disabled={props.disabled}
      >
        {props.placeholder && (
          <option disabled value="" style={{ fontStyle: "italic" }}>
            {props.placeholder}
          </option>
        )}
        {props.options &&
          props.options.map((e) => {
            if (typeof e === "string") {
              return (
                <option key={e} value={e}>
                  {e}
                </option>
              );
            } else
              return (
            //@ts-ignore
                <option key={e.value} value={e.value}>
                  {e.label}
                </option>
              );
          })}
        {props.children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default SelectField;
