import axios from "axios";
import Loading from "components/Loading";
import useApi from "hooks/useApi";
import React from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";

type InterdistrictSettings = {
  ActiveAcademicYears: string[];
  ActiveAcademicYearsChanged: boolean;
  ActiveAcademicYearsValid?: boolean;
};

type Props = {};

const Settings = (props: Props) => {
  const Interdistrict = useApi("/api/Applications/Interdistrict/Settings");
  const [InterdistrictSettings, setInterdistrictSettings] =
    React.useState<InterdistrictSettings>(null!);
  React.useEffect(() => {
    console.log("Interdistrict Settings", Interdistrict.data);
    if (!Interdistrict.loading && Interdistrict.data)
      setInterdistrictSettings({
        ...Interdistrict.data,
        ActiveAcademicYearsChanged: false,
      });
  }, [Interdistrict.data]);

  return (
    <Card>
      <Card.Header>Interdistrict Application Settings</Card.Header>
      <Card.Body>
        {Interdistrict.loading ? (
          <Loading />
        ) : (
          <>
            <Form.Group>
              <InputGroup>
                <InputGroup.Text>Active Academic Years</InputGroup.Text>
                <Form.Control
                  isInvalid={
                    InterdistrictSettings?.ActiveAcademicYearsValid === false
                  }
                  type="text"
                  value={InterdistrictSettings?.ActiveAcademicYears || ""}
                  onChange={(e) => {
                    let yrs = e.target.value.trim().split(",");
                    let valid = true;
                    for (let i = 0; i < yrs.length; i++) {
                      if (!yrs[i].match(/^[0-9]{0,4}$/)) {
                        valid = false;
                      }
                    }

                    setInterdistrictSettings({
                      ...InterdistrictSettings,
                      ActiveAcademicYears: yrs,
                      ActiveAcademicYearsChanged: true,
                      ActiveAcademicYearsValid: valid,
                    });
                  }}
                />
                {InterdistrictSettings?.ActiveAcademicYearsChanged &&
                  InterdistrictSettings?.ActiveAcademicYearsValid && (
                    <Button
                      variant="success"
                      onClick={() => {
                        axios
                          .post(`/api/Applications/Interdistrict/Settings`, {
                            ActiveAcademicYears:
                              InterdistrictSettings.ActiveAcademicYears.join(
                                ","
                              ),
                          })
                          .then((res) => {Interdistrict.refresh()});
                      }}
                    >
                      <i className="fa fa-save" />
                    </Button>
                  )}
                {InterdistrictSettings?.ActiveAcademicYearsChanged && (
                  <Button
                    variant="warning"
                    onClick={() => {
                      setInterdistrictSettings({
                        ...InterdistrictSettings,
                        ActiveAcademicYears:
                          Interdistrict.data.ActiveAcademicYears,
                        ActiveAcademicYearsChanged: false,
                        ActiveAcademicYearsValid: undefined,
                      });
                    }}
                  >
                    <i className="fa fa-ban" />
                  </Button>
                )}
                <Form.Control.Feedback type="invalid">
                  Invalid years. Must be a comma-separated list of year numbers.
                  Ex: "2023,2024" will be for 2022-<b>2023</b> and 2023-
                  <b>2024</b>.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default Settings;
