import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import { useField, useFormikContext } from "formik";
import Field from "./Field";
import { Alert, Card } from "react-bootstrap";

type FileProps = {
  name: string;
  link: string;
  label?: string;
  disabled?: boolean;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
};

function File({ name, link, onDelete, disabled, label }: FileProps) {
  return (
    <ListGroup.Item>
      {label && <b>{label}: </b>}
      <a href={link} target="new">
        {name}
      </a>
      &nbsp;
      {disabled ? (
        <> </>
      ) : (
        <Button onClick={onDelete} variant="danger" disabled={disabled}>
          <i className="fa fa-times" />
        </Button>
      )}
    </ListGroup.Item>
  );
}

type Props = {
  label: React.ReactNode;
  name: string;
  disabled?: boolean;
  noValid?: boolean;
  noInvalid?: boolean;
  multiple?: boolean;
  required?: boolean;
};

const FileField = ({
  label,
  name,
  disabled,
  noValid,
  noInvalid,
  multiple,
  required,
}: Props) => {
  const [field, meta, helpers] = useField({ name });
  const formik = useFormikContext();

  const [error, setError] = useState<string>(null!);
  const [files, setFiles] = useState(field.value || []);
  const submitting = formik?.isSubmitting && formik?.isValidating;
  const controlRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!field.value) return;
    if (
      Array.isArray(files) &&
      field.value !== undefined &&
      files.length !== field.value.length
    )
      setFiles(field.value);
  }, [field.value]);

  useEffect(() => {
    if (disabled) return;
    for (let i = 0; i < files.length; i++) {
      axios.get(`/api/files/${files[i].fileId}/check`).then((res) => {
        console.log(res);
        if (res.data.status !== "Success") {
          let newFiles = [...files];
          // newFiles.splice(i, 1);
          setFiles(newFiles);
          field.onChange({
            target: {
              name: name,
              value: newFiles,
            },
          });
          field.onBlur({
            target: {
              name: name,
              value: newFiles,
            },
          });
          setError(
            `There was an issue with your uploaded file: ${files[i].name}. Please re-upload it.`
          );
        }
      });
    }
  }, [submitting]);

  // Intercept the handleChange to cache the returned data from the server rather than the input field values.
  const handleChange = (d: any) => {
    let fData = new FormData();
    for (let i = 0; i < d.target.files.length; i++)
      fData.append("" + i, d.target.files[i]);
    fData.append("field", name);
    fData.append("form", "Interdistrict");
    axios
      .post("/api/files", fData)
      .then((res) => {
        if (res.data.status === "Failure") {
          setError(res.data.message);
          return;
        }
        setError("");
        let _files = [...files];
        if (Array.isArray(res.data.data)) {
          for (let i in res.data.data) {
            _files.push({
              fileLink: `/files/temp/${res.data.data[i].fileId}`,
              ...res.data.data[i],
            });
          }
        } else {
          _files.push({
            fileLink: `/files/temp/${res.data.data.fileId}`,
            ...res.data.data,
          });
        }
        // field.onChange(e);
        // helpers.setValue(_files);
        // field.onBlur({target: d.target});
        setFiles(_files);
        field.onChange({
          target: {
            name: name,
            value: _files,
          },
        });
        field.onBlur({
          target: {
            name: name,
            value: _files,
          },
        });
      })
      .catch((err) => {
        console.log("Caught error", err);
        if (err?.message) {
          setError(err);
        } else {
          setError("An unexpected error has occurred. Please try again.");
        }
      });
  };

  const deleteFile = (fileId: string) => {
    axios
      .delete(`/api/files`, {
        data: {
          field: name,
          form: "Interdistrict",
          fileId: fileId,
        },
      })
      .then((res) => {
        console.log("delete res", res);
        let _files = [...files];
        for (let i = 0; i < _files.length; i++) {
          if (_files[i].fileId === fileId) _files.splice(i, 1);
        }
        console.log("After deleted, files: ", _files);
        setFiles(_files);
        field.onChange({
          target: {
            name: name,
            value: _files,
          },
        });
        field.onBlur({
          target: {
            name: name,
            value: _files,
          },
        });
      })
      .catch((res) => {});
  };
  return (
    <Form.Group>
      <Card border={meta.error && "danger"}>
        <Card.Header>
          {label}
          {required && <span className="label-required">*</span>}{" "}
          <Button
            variant={meta.error || files.length < 1 ? "danger" : "success"}
            disabled={disabled}
            onClick={() => {
              console.log(controlRef);
              if (controlRef !== null && controlRef.current !== null)
                controlRef.current.click();
            }}
          >
            <i className="fa fa-plus" /> Choose File...
          </Button>
        </Card.Header>
        <Card.Body>
          <Field name={name} label="">
            {({ label, name, isValid, isInvalid, field, meta }) => (
              <>
                <Form.Control
                  type="file"
                  className="hidden"
                  //@ts-ignore
                  ref={controlRef}
                  name={`uploader-${name}`}
                  onChange={handleChange}
                  multiple
                  disabled={disabled}
                  accept="image/*,.pdf,.docx"
                  isValid={isValid}
                  isInvalid={isInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  {error || meta.error}
                </Form.Control.Feedback>
              </>
            )}
          </Field>
          <ListGroup>
            {Array.isArray(files) && files.length > 0 ? (
              files.map((e: any, i: number) => (
                <File
                  key={`${i}-${e.fileId}`}
                  name={e.name}
                  link={e.fileLink}
                  disabled={disabled}
                  onDelete={() => deleteFile(e.fileId)}
                />
              ))
            ) : (
              <Alert variant="danger">
                You have not yet uploaded any files.
              </Alert>
            )}
          </ListGroup>
        </Card.Body>
      </Card>
    </Form.Group>
  );
};

FileField.propTypes = {};
FileField.File = File;

export default FileField;
