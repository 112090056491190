// import * as yup from 'yup'

const yup = require("yup");
// import { AllStates } from './lists'

const AllStates = require("./lists").AllStates;

const schema = yup.object().shape({
  Applicant: yup.object().shape({
    LastName: yup.string().required("Required"),
    FirstName: yup.string().required("Required"),
    Phone: yup
      .string()
      .matches(
        /^([^0-9]*[0-9]){10}$/,
        "Please enter a valid US 10-digit phone number."
      )
      .required("Required"),
    Email: yup
      .string()
      .matches(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        "Please enter a valid email address."
      )
      .required("Required"),
    Initials: yup.string().min(2).required("Required"),
    ConfirmRequestConditions: yup
      .string()
      .required("Required")
      .when("Initials", (i, schema) =>
        schema.oneOf([i], "Initials must match.")
      ),
    ConfirmRequestTimeline: yup
      .string()
      .required("Required")
      .when("Initials", (i, schema) =>
        schema.oneOf([i], "Initials must match.")
      ),
    ConfirmAR5117: yup
      .string()
      .required("Required")
      .when("Initials", (i, schema) =>
        schema.oneOf([i], "Initials must match.")
      ),
    ConfirmRequestProcess: yup
      .string()
      .required("Required")
      .when("Initials", (i, schema) =>
        schema.oneOf([i], "Initials must match.")
      ),
    ConfirmTerms: yup
      .string()
      .required("Required")
      .when("Initials", (i, schema) =>
        schema.oneOf([i], "Initials must match.")
      ),
    ConfirmApplication: yup
      .string()
      .required("Required")
      .when("Initials", (i, schema) =>
        schema.oneOf([i], "Initials must match.")
      ),
    ConfirmEnrollmentDeadline: yup
      .string()
      .required("Required")
      .when("Initials", (i, schema) =>
        schema.oneOf([i], "Initials must match.")
      ),
    AcademicYear: yup
      .number()
      .min( (new Date()).getFullYear()-1 , "Please select a valid Academic year")
      .max( (new Date()).getFullYear()+1 , "Please select a valid Academic year")
      .required("Required")
  }),
  Student: yup.object().shape({
    LastName: yup.string().required("Required"),
    FirstName: yup.string().required("Required"),
    MiddleName: yup.string(),
    IncomingGrade: yup
      .number()
      .min(-2, "Select a valid grade.")
      .max(12, "Select a valid grade.")
      .required("Required"),
    Birthdate: yup
      .date()
      .min(
        new Date(
          new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 20 /*20 years */
        ),
        "Must be of school age."
      )
      .max(
        new Date(
          new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 3 /*20 years */
        ),
        "Must be of school age."
      )
      .required("Required")
      .typeError("Select a valid date."),
    MostRecentSchoolName: yup.string().required("Required"),
    MostRecentSchoolCity: yup.string().required("Required"),
    MostRecentSchoolState: yup
      .string()
      .oneOf([...AllStates, "Out of State"])
      .required("Required"),
    TranscriptsDoc: yup
      .array()
      .when(["IncomingGrade","NewStudent"], (grade, newStudent, schema) => {
        if (grade > 0 && newStudent) return schema.min(1, "You must upload the most recent transcript / grade report.");
      }),
    ReleaseDoc: yup
      .array()
      .min(1, "You must upload an IDA release from your resident school district.")
      .required("You must upload an IDA release from your resident school district."),
    FirstChoiceSchool: yup
      .number()
      // .oneOf([0, 1, 2, 3, 4, 5, 6, 21, 22, 23, 25], "Select a School")
      .when("NewStudent", (o, schema) => 
        (o === true || o === "true") ? schema.oneOf([1, 2, 3, 4, 5, 6, 21, 22, 23, 25], "Select a school") : schema)
      .required("Required"),
    SecondChoiceSchool: yup
      .number()
      .oneOf([0, 1, 2, 3, 4, 5, 6, 21, 22, 23, 25], "Select a School"),
    ThirdChoiceSchool: yup
      .number()
      .oneOf([0, 1, 2, 3, 4, 5, 6, 21, 22, 23, 25], "Select a School"),
    NewStudent: yup.bool().required("You must choose an application type."),
    ReasonForRequest: yup.bool()
      .when(['GuardianActiveDutyMilitary','GuardianTUSDEmployee','Other','MidYearMove','SiblingAttendsTUSD'], (Mil, Emp, Other, Mid, Sibling, schema) => {
        if (!Mil && !Emp && !Other && !Mid && !Sibling) return schema.oneOf([true, "true"], "You must choose a reason for this request.")
        return schema;
      }),
    GuardianActiveDutyMilitary: yup.bool(),
    GuardianActiveDutyMilitaryDoc: yup
      .array()
      .when("GuardianActiveDutyMilitary", (o, schema) =>
        o ? schema.min(1, "You must upload a copy of your military documents.") : schema
      ),
    GuardianTUSDEmployee: yup.bool(),
    GuardianTUSDEmployeeDoc: yup
      .array()
      .when("GuardianTUSDEmployee", (o, schema) =>
        o ? schema.min(1, "You must upload a recent pay stub.") : schema
      ),
    MidYearMove: yup.bool(),
    SiblingAttendsTUSD: yup.bool(),
    Other: yup.bool(),
    OtherText: yup
      .string()
      .when("Other", (o, schema) => (o ? schema.required("Required") : schema)),
  }),
  Guardians: yup
    .array()
    .of(
      yup.object().shape({
        LastName: yup.string().min(1).required("Required"),
        FirstName: yup.string().required("Required"),
        Relation: yup
          .string()
          .oneOf(["Mother", "Father", "Guardian"])
          .required("Required"),
        Address: yup.string().required("Required"),
        City: yup.string().required("Required"),
        State: yup.string().oneOf(AllStates).required("Required"),
        Zip: yup
          .number()
          .min(0, "Invalid Zip")
          .max(100000, "Invalid Zip")
          .required("Required"),
        Email: yup
          .string()
          .matches(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            "Please enter a valid email address."
          ),
        HomePhone: yup
          .string()
          .matches(
            /^([^0-9]*[0-9]){10}$/,
            "Please enter a valid US 10-digit phone number."
          )
          .required("Required"),
        CellPhone: yup
          .string()
          .matches(
            /^(([^0-9]*[0-9]){10}|)$/,
            "Please enter a valid US 10-digit phone number."
          ),
        WorkPhone: yup
          .string()
          .matches(
            /^(([^0-9]*[0-9]){10}|)$/,
            "Please enter a valid US 10-digit phone number."
          ),
        LivesWith: yup.bool(),
      })
    )
    .ensure()
    .min(1)
    .max(2)
    .required("Required"),
  Siblings: yup
    .array(
      yup.object().shape({
        LastName: yup.string().required("Required"),
        FirstName: yup.string().required("Required"),
        Birthdate: yup
          .date()
          .min(
            new Date(
              new Date().getTime() -
                1000 * 60 * 60 * 24 * 365 * 20 /*20 years */
            ),
            "Must be of school age."
          )
          .max(
            new Date(
              new Date().getTime() - 1000 * 60 * 60 * 24 * 365 * 3 /*20 years */
            ),
            "Must be of school age."
          )
          .required("Required")
          .typeError("Select a valid date."),
        Grade: yup.number().min(-2).max(12).required("Required"),
        School: yup.string().required("Required"),
        SchoolCity: yup.string().required("Required"),
        SchoolState: yup.string().oneOf(AllStates).required("Required"),
      })
    )
    .min(0)
    .max(10),
  AdditionalQuestions: yup.object().shape({
    PreviouslyAttendedTUSD: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
    }),
    SpedIEP: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", {
          is: (response) => response === true || response === "true",
          then: yup.string().required("Required"),
        }),
      // (r, schema) =>
      //   r === true || r === "true" ? schema.required("Required") : schema
      // ),
      Doc: yup
        .array()
        .when("Response", {
          is: (response) => response === true || response === "true",
          then: yup.array().required("Required").min(1, "You must upload a copy of this child's 504 plan."),
        }),
      // .when("Response", (r, schema) =>
      //   r === true || r === "true" ? schema.required("Required") : schema
      // ),
    }),
    AttendanceProblems: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
    }),
    AcademicPerformanceProblems: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
    }),
    GradeRepeated: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
    }),
    Suspensions: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
    }),
    Expulsions: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
    }),
    AltEdPrograms: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
    }),
    SportsOrExtracurricular: yup.object().shape({
      Response: yup.bool().required("Required"),
      When: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
      Where: yup
        .string()
        .when("Response", (r, schema) =>
          r === true || r === "true" ? schema.required("Required") : schema
        ),
    }),
  }),
  InterdistrictAttendanceAgreement: yup.object().shape({
    GuardianSignature: yup.string().required("You must type your name to acknowledge and sign this agreement."),
    StudentSignature: yup.string().required("Your child must type their name to acknowledge and sign this agreement."),
  }),
});

const empty = {
  Applicant: {
    LastName: "",
    FirstName: "",
    Phone: "",
    Email: "",
    Initials: "",
    ConfirmRequestConditions: "",
    ConfirmRequestTimeline: "",
    ConfirmAR5117: "",
    ConfirmRequestProcess: "",
    ConfirmTerms: "",
    ConfirmApplication: "",
    AcademicYear: "",
  },
  Student: {
    LastName: "",
    FirstName: "",
    MiddleName: "",
    IncomingGrade: "",
    Birthdate: "",
    NewStudent: "",
    MostRecentSchoolName: "",
    MostRecentSchoolCity: "",
    MostRecentSchoolState: "",
    TranscriptsDoc: [],
    FirstChoiceSchool: 0,
    SecondChoiceSchool: 0,
    ThirdChoiceSchool: 0,
    ReasonForRequest: false,
    NewStudent: "",
    GuardianActiveDutyMilitary: false,
    GuardianTUSDEmployee: false,
    MidYearMove: false,
    SiblingAttendsTUSD: false,
    Other: false,
    OtherText: "",
  },
  Guardians: [
    {
      Relation: "",
      LastName: "",
      FirstName: "",
      Address: "",
      City: "",
      State: "",
      Zip: "",
      HomePhone: "",
      CellPhone: "",
      WorkPhone: "",
      LivesWith: false,
      Email: "",
    },
  ],
  Siblings: [
    {
      Name: "",
      Birthdate: "",
      Grade: "",
      School: "",
    },
  ],
  AdditionalQuestions: {
    PreviouslyAttendedTUSD: { Response: "", When: "", Where: "" },
    SpedIEP: { Response: "", When: "", Where: "" },
    AttendanceProblems: { Response: "", When: "", Where: "" },
    AcademicPerformanceProblems: { Response: "", When: "", Where: "" },
    GradeRepeated: { Response: "", When: "", Where: "" },
    Suspensions: { Response: "", When: "", Where: "" },
    Expulsions: { Response: "", When: "", Where: "" },
    AltEdPrograms: { Response: "", When: "", Where: "" },
    SportsOrExtracurricular: { Response: "", When: "", Where: "" },
  },
  InterdistrictAttendanceAgreement: {
    StudentSignature: "",
    GuardianSignature: "",
  }
};

module.exports = { schema, empty };
